import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PatientsService } from '../services/patients.service';
import { PatientVo, ProviderService } from 'api-clinician-app';
import { UiStateService } from '../services/ui-state.service';
import { switchMap, tap, take, withLatestFrom, map } from 'rxjs/operators';
import { ActivePatient } from '../services/state/ui-state/ui-state.store';
import { ApplicationInsightsService, SeverityLevel } from '../services/application-insights.service';
import { ClinicalEncounterService } from '../services/clinical-encounter.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDataResolverService implements Resolve<PatientVo> {
  constructor(
    private patientsService: PatientsService,
    private clinicalService: ClinicalEncounterService,
    private uiStateService: UiStateService,
    private appInsightsService: ApplicationInsightsService,
    private providerService: ProviderService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PatientVo> {
    const params = { tenantId: route.paramMap.get('tenantId'), patientId: route.paramMap.get('patientId') };
    if (!params.tenantId || !params.patientId) {
      this.uiStateService.changeCurrentPatientId(null);
      return of(null);
    }

    return this.patientsService.patientById$(params.patientId).pipe(
      take(1),
      withLatestFrom(this.providerService.provider$.pipe(map(p => p.PracticeTenantId))),
      switchMap(([patient, practiceTenantId]) =>
        !!patient && !!patient.details && !patient.isIncomplete
          ? of(patient.details)
          : this.patientsService.getAllPatientDetails(params.tenantId, params.patientId, practiceTenantId)
      ),
      take(1),
      tap({
        next: patient => {
          if (!!patient) {
            // not quite sure how this can happen though, but patching out the error and logging more detail for later
            const activePatient: ActivePatient = {
              patientId: patient.PatientId,
              patientName: `${patient.PatientDetails.FirstName} ${patient.PatientDetails.Surname}`,
            };
            this.uiStateService.addActivePatient(activePatient);
            this.uiStateService.changeCurrentPatientId(patient.PatientId);
          } else {
            this.appInsightsService.trackException(
              new Error(
                `NULL patient returned in PatientDataResolver while trying to resolve patient ` +
                  `[ tenantId: ${params.tenantId}, patientId: ${params.patientId}`
              ),
              'PatientDataResolverService.resolve()'
            );
          }
        },
      })
    );
  }
}
