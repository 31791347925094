import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, NavigationService, ProviderService, UiStateService, TokenProvidersClient, PatientsService } from '@app/core/services';
import { tap, map, filter, distinctUntilChanged, switchMap, shareReplay, withLatestFrom, take } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { PathologyService } from '@app/core/services/pathology.service';
import * as _ from 'lodash';
import { SidebarLocations } from '@app/core/services/state/ui-state/ui-state.store';
import { combineLatest, Observable, of } from 'rxjs';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { ConfigService } from '@app/core/services/config.service';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { shareReplayDestroyed } from '@app/core/functions/share-replay-destroyed';
import { FRONT_DESK_PERMISSION } from '@app/core/services/role-manager.service';
import {
  UserFeedbackDialogComponent,
  UserFeedbackDialogModel,
} from '@app/shared/components/user-feedback-dialog/user-feedback-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConsultationDialogComponent } from '@app/shared/components/nora-component/nora-consultation-dialog/nora-consultation-dialog.component';
import { DialogManagementService, DialogService } from '@app/shared/components/nora-component/nora-services/nora-dialog.service';
import { AudioRecorderService } from '@app/modules/sidebar/speech-to-notes/services/audio-recorder.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  SidebarLocations = SidebarLocations;

  currentSidebarRoute = '';

  currentTenantId$ = this.authService.currentTenantId$;

  FRONT_DESK_PERMISSION = FRONT_DESK_PERMISSION;

  waitingRoomCount$ = combineLatest([
    this.providerService.waitingRoom$,
    this.uiStateService.practiceProvidersStateUI$.pipe(map(providers => providers.filter(f => f.selectedInWaitingRoom))),
  ]).pipe(
    map(
      ([waitingRoom, providers]) => waitingRoom?.filter(f => providers.some(s => s.providerId === f.PatientEvent.PracticeId))?.length || 0
    )
  );

  pathologyUnactionedItems$ = this.providerService.provider$.pipe(
    switchMap(provider => this.pathologyService.getReportsUnactionedForToday(provider?.PracticeTenantId)),
    tap(results => this.providerService.updateTodayReports(results))
    // shareReplay(1)
  );

  pathologyUnactionedCount$ = combineLatest([this.providerService.todayReports$, this.pathologyUnactionedItems$]).pipe(
    map(([unactioned]) => unactioned.length)
  );

  overdueTasksCount$ = this.providerService.overdueTasks$.pipe(map(s => s.length));

  sidebar$ = this.uiStateService.sidebar$.pipe(
    tap(sidebar => (this.currentSidebarRoute = sidebar)),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  params$ = combineLatest([this.authService.currentTenantId$, this.uiStateService.currentPatientId$]).pipe(
    map(([tenantId, patientId]) => ({ tenantId, patientId })),
    distinctUntilChanged()
  );

  encounter$ = this.params$.pipe(
    switchMap(params => this.clinicalEncounterService.encounterInProgress$(params.tenantId, params.patientId)),
    untilDestroyed(this),
    shareReplay(1)
  );

  isInPatientContext$ = this.router.events.pipe(
    filter(f => f instanceof NavigationEnd),
    map(m => m as NavigationEnd),
    map(nav => nav.url.includes('patient'))
  );
  //
  existingConsult$ = combineLatest([
    // todo refactor to not rely on waiting room
    // switchMap(params => this.clinicalEncounterService.checkPatientEncounter(params.tenantId, params.patientId)),
    this.params$.pipe(switchMap(params => this.clinicalEncounterService.encounterInProgressOrNulll$(params.tenantId, params.patientId))),
    this.router.events.pipe(
      filter(f => f instanceof NavigationEnd),
      map(m => m as NavigationEnd),
      map(nav => nav.url.includes('consult'))
    ),
  ]).pipe(
    map(([en, a]) => en !== null && a),
    distinctUntilChanged(_.isEqual),
    shareReplayDestroyed(1)
  );

  notificationsCount$ = this.providerService.notifications$.pipe(
    map(notifications => notifications?.filter(f => f.Status === 'raised').length || 0)
  );

  medicineInteractions$ = this.params$.pipe(
    switchMap(params => this.clinicalEncounterService.medicineInteractions$(params.patientId)),
    untilDestroyed(this)
  );

  medicineInteractionMaxServerity$: Observable<number> = this.medicineInteractions$.pipe(
    map(interactions => {
      if (!interactions || interactions?.length === 0) {
        return 0;
      }
      return Math.min(...interactions.map(i => i.Severity));
    }),
    untilDestroyed(this)
  );

  IsEnabledConsultationNotesTab$ = this.providerService.IsEnabledConsultationNotesTab$;

  speechToNotesEnabled$ = this.providerService.speechToNotesEnabled$;
  isSpeechToNotesActive$ = this.noraDialogManagementService.isSpeechToNotesActive$;

  // isEncounterInProgress$ = combineLatest([
  //   this.params$.pipe(switchMap(params => this.clinicalEncounterService.checkPatientEncounter(params.tenantId, params.patientId))),
  //   this.params$.pipe(
  //     switchMap(params => this.clinicalEncounterService.encounterInProgressUiState$(params.tenantId, params.patientId)),
  //     map(m => !!m)
  //   ),
  // ]).pipe(
  //   withLatestFrom(this.isInPatientContext$),
  //   map(([encounterState, isInPatientContext]) => {
  //     const [remoteState, localUiState] = encounterState;

  //     return isInPatientContext && (remoteState || localUiState);
  //   }),
  //   distinctUntilChanged(),
  //   untilDestroyed(this)
  // );

  constructor(
    private authService: AuthService,
    private providerService: ProviderService,
    private pathologyService: PathologyService,
    private navService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uiStateService: UiStateService,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private configService: ConfigService,
    private tokenProviderClient: TokenProvidersClient,
    private appInsightsService: ApplicationInsightsService,
    private clinicalEncounterService: ClinicalEncounterService,
    private dialog: MatDialog,
    private noraDialogManagementService: DialogManagementService,
    private noraAudioRecorderService: AudioRecorderService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {}

  showHelp() {
    this.ngxZendeskWebwidgetService?.zE.activate();
  }

  monitorSideBar() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => {
          const currentRoutes = this.activatedRoute.snapshot.children.find(c => c.outlet === 'sidebar');
          if (currentRoutes) {
            // sidebar loaded...
            const sidebarRoute = currentRoutes.firstChild;
            return sidebarRoute.routeConfig.path;
          } else {
          }
        }),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(sidebarRoute => {
        if (sidebarRoute) {
          this.currentSidebarRoute = sidebarRoute;
        } else {
          this.currentSidebarRoute = '';
        }
      });
  }

  toggleSidebar(event, link) {
    const sidebarLocation = this.currentSidebarRoute !== link ? link : SidebarLocations.HIDDEN;
    event.preventDefault();
    this.checkSpeechToNotesRecordingSession()
      ? this.snackbar.open('Please stop/discard the recording before navigating to another page.', 'Close', { duration: 4000 })
      : this.uiStateService.navigateSidebar(sidebarLocation);
  }

  openConsultationDialog() {
    this.encounter$
      .pipe(
        take(1),
        withLatestFrom(this.params$),
        tap(([encounter, params]) => {
          const data = {
            practiceId: params.tenantId,
            encounterId: encounter.EncounterId,
          };
          this.noraDialogManagementService.openDialog(ConsultationDialogComponent, data);
        })
      )
      .subscribe();
  }

  checkSpeechToNotesRecordingSession() {
    return this.noraAudioRecorderService.getRecordingStatus();
  }

  openUserFeedback(): void {
    this.params$
      .pipe(
        take(1),
        switchMap(params => {
          const dialogData: UserFeedbackDialogModel = {
            tenantId: params.tenantId,
          };
          const dialogRef = this.dialog.open(UserFeedbackDialogComponent, {
            width: '640px',
            data: dialogData,
          });
          return dialogRef.afterClosed();
        })
      )
      .subscribe();
  }

  navToBridgeIt() {
    this.authService.currentTenantId$
      .pipe(
        switchMap(tenantId => this.tokenProviderClient.getTokenForBridgeit(tenantId, 'hbclinical')),
        map(token => `${this.configService.config.bridgeitUrl}?token=${token}`),
        withLatestFrom(this.authService.currentTenantId$),
        map(([token, tenantId]) => {
          this.appInsightsService.trackEvent(AppInsightTags.SUPPORT_BRIDGEIT, { tenantId, referrer: 'sidebar-nav' });
          return token;
        }),
        distinctUntilChanged(_.isEqual),
        untilDestroyed(this)
      )
      .subscribe(url => window.open(url, '_blank'));
  }

  ngOnDestroy() {}
}
