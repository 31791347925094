import { Injectable, Inject } from '@angular/core';
import {
  ClinicalClient,
  PatientClient,
  ClinicalNewClient,
  PatientDocumentClient,
  EncounterVo,
  ClinicalMetricVo,
  ClinicalPatientClient,
  PatientDocumentResourceClient,
  ProviderCommunicationEmailVo,
  EncounterLineItemVo,
  WaitingRoomClient,
  PatientEventClient,
  CancelPatientVisit,
  CommunicationVo,
  DiagnosisVo2,
  MedicalCertificate,
  CommunicationTemplateClient,
  PatientDocumentVo,
  Symptom,
  SymptomQuestion,
  SymptomQuestionAnswer,
  UpdateEncounterCommunication,
  EncounterFollowUpVo,
  MedicationPrescription,
  MedicationPrescriptionLine,
  ICDPredictionRequest,
  EncounterDiagnosisTokenItemVo,
  FormularyType,
  API_BASE_URL,
  PatientFileNoteVo,
  EncounterHeaderVoPlaceOfService,
  Medicines,
  StaticCategory,
  EncounterTemplateVo,
  Consumables,
  SmartCategories2,
  EncounterPlanOutcomeVo,
  EncounterStatusVo,
  EncounterDiagramVo,
  CommunicationTemplateVo,
  RestApiResultOfCompleteEncounterResult,
  RestApiResultOfString,
  PatientConfigurationVo,
  Dosages,
  MedicalInsuranceVo,
  MedicineInfo,
  MedicineInteractionVo,
  PatientEventVo,
  PatientEventVoStatus,
  ProviderConfigurationVo,
  PatientCommunicationFollowupVoDeliveryType,
  PatientCommunicationFollowupVoPriority,
  RestApiResultOfEncounterVo,
  CommunicationRenderRequest,
} from './api-client.service';
import {
  ClinicalEncountersStore,
  SymptomQuestionSection,
  ClinicalEncounterUIViewModel,
  SectionExpanded,
  DEFAULT_CATEGORIES,
  MedicineInteractionStatus,
} from './state/clinical-encounter/clinical-encounter.store';
import { ClinicalEncountersQuery } from './state/clinical-encounter/clinical-encounter.query';
import {
  tap,
  map,
  distinctUntilChanged,
  take,
  filter,
  switchMap,
  catchError,
  mapTo,
  defaultIfEmpty,
  withLatestFrom,
  delay,
  toArray,
} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { arrayUpdate, arrayAdd, arrayRemove, arrayUpsert, action, withTransaction, transaction } from '@datorama/akita';
import { ReferenceDataService, IClinicalMetricValue, AccountNoRegex } from './reference-data.service';
import * as uuidV4 from 'uuid/v4';
import { combineLatest, of, BehaviorSubject, EMPTY, Observable, concat, forkJoin, throwError, from } from 'rxjs';
import { ProviderConfigurationKey, ProviderService } from './provider.service';
import { PatientsService } from './patients.service';
import { DocumentUploadClient } from './document-upload.client';
import {
  ConsumableViewModel,
} from '@app/modules/patient/patient-consultation/components/assessment/consumables/models/consumable-view-model.model';
import { CacheService } from './cache.service';
import { AlgoliaRestService } from './algolia-rest.service';
import { EncounterLettersStore, Letter } from './state/encounter-letters/encounter-letters.store';
import { EncounterLettersQuery } from './state/encounter-letters/encounter-letters.query';
import {
  convertMedicineToPrescriptionLine, enrichMedicineViewModelsInfoFlags, getFriendlyDosageDescription,
} from '@app/modules/patient/patient-consultation/components/assessment/medicines/models/medicine-view-model.util';
import {
  formDosageMeasurement,
  MedicineViewModel,
} from '@app/modules/patient/patient-consultation/components/assessment/medicines/models/medicine-view-model.model';
import { MerakiClientService } from './meraki-client.service';
import { PatientInWaitingRoomViewModel } from './state/provider/provider.store';
import { PatientStateModel } from './state/patients/patients.store';
import { patientAge } from '@app/shared/functions/patientAge';
import { switchTap } from '../functions/switch-tap';
import {
  AllowedPpeConsultReasons,
  ConsultReason,
  EncounterType,
  HospitalReasons,
  PatientEventType,
  VirtualVisitTypes,
} from './state/clinical-encounter/clinical-encounter.model';
import { ApplicationInsightsService } from './application-insights.service';
import { AdImpression } from './view-models/smartmeds-ads-models';
import { ERROR_CODE } from './meraki-models/error-models';
import MeiliSearch, { Hits } from 'meilisearch';
import { ConfigService } from './config.service';
import * as guidByString from 'uuid-by-string';

export interface SearchResult {
  Code: string;
  Description: string;
  Parameters: any;
}
type Key = string | number;
type KeyExtractor<T> = (item: T) => Key;
const babyMetricTypes = ['Weight', 'Height', 'BMI', 'Head-circumference'];

@Injectable({
  providedIn: 'root',
})
export class ClinicalEncounterService {
  inProgressEncounters$ = this.clinicalEncounterQuery.inProgressEncounters$;

  showQuickNotesOnEncounterSummary$ = new BehaviorSubject<boolean>(false);
  private readonly meiliSearchLookupClient!: MeiliSearch;

  constructor(
    private algoliaRestService: AlgoliaRestService,
    private referenceDataService: ReferenceDataService,
    private communicationTemplateClient: CommunicationTemplateClient,
    private patientClient: PatientClient,
    private clinicalClient: ClinicalClient,
    private clinicalNewClient: ClinicalNewClient,
    private documentClient: PatientDocumentClient,
    private patientDocumentResourceClient: PatientDocumentResourceClient,
    private clinicalPatientClient: ClinicalPatientClient,
    private waitingRoomClient: WaitingRoomClient,
    private patientEventClient: PatientEventClient,
    private clinicalEncounterStore: ClinicalEncountersStore,
    private clinicalEncounterQuery: ClinicalEncountersQuery,
    private encounterLettersStore: EncounterLettersStore,
    private encounterLettersQuery: EncounterLettersQuery,
    private providerService: ProviderService,
    private documentUploadClient: DocumentUploadClient,
    private patientsService: PatientsService,
    private cacheService: CacheService,
    private configService: ConfigService,
    private merakiService: MerakiClientService,
    private appInsightsService: ApplicationInsightsService,
    @Inject(API_BASE_URL) private baseUrl: string,
  ) {
    this.meiliSearchLookupClient = new MeiliSearch({
      host: configService.config.meiliSearchLookup.host,
      apiKey: configService.config.meiliSearchLookup.key,
    });
  }

  encounterInProgressOrNulll$(practiceId: string, patientId: string) {
    return this.clinicalEncounterQuery.patientInProgressEncounter$(patientId);
  }

  encounterInProgress$(practiceId: string, patientId: string) {
    return this.clinicalEncounterQuery.patientInProgressEncounter$(patientId).pipe(filter(t => t != null));
  }

  getSmartTemplatesForDiagnosisTokens$(practiceId: string, patientId: string): Observable<SmartCategories2[]> {
    return this.encounterInProgress$(practiceId, patientId).pipe(
      map(encounter => encounter.Diagnosis || []),
      map(diagnoses => diagnoses.map(d => d.DiagnosisToken)),
      map(tokens => _.uniq(tokens.filter(token => !!token))),
      distinctUntilChanged(_.isEqual),
      map(tokens => (tokens || []).map(token => this.getSmartTemplates(practiceId, patientId, token))),
      switchMap(sts => forkJoin(sts)),
      map(sts => sts.filter(st => !!st)), // ignore smart template results that don't come back
    );
  }

  encounterInProgressUiState$(practiceId: string, patientId: string) {
    return this.clinicalEncounterQuery.ui.selectEntity(patientId);
  }

  upsetSectionExpandedValue(tenantId: string, patientId: string, updatedSection: SectionExpanded) {
    this.clinicalEncounterStore.ui.update(patientId, entity => {
      const updatedSections = arrayUpdate(
        entity.expansionSections,
        sectionsList => sectionsList.section === updatedSection.section,
        updatedSection,
      );
      return {
        ...entity,
        expansionSections: updatedSections,
      };
    });
  }

  getMedicalCertificateLegacy(practiceId: string, encounterId: string) {
    return this.clinicalClient
      .getEncounter(practiceId, encounterId).pipe(
        withLatestFrom(this.providerService.provider$.pipe(take(1))),
        switchMap(([encounter, provider]) =>
          (encounter.MedicalCertificate.MedicalCertificateId == '00000000-0000-0000-0000-000000000000')
            // if certificate is empty firebase storage doesn't contaisn proper report, use old way to generate certificate on the fly
            ? this.clinicalClient
              .getEncounter(practiceId, encounterId)
              .pipe(
                switchMap(s => this.clinicalClient.generateMedicalCertificateReport(practiceId, s)),
                map(s => s.Data),
              )
            : this.getEncounterCommunicationBody(
              provider.PracticeNumber, encounter.MedicalCertificate.MedicalCertificateId)),
        map(body => ({ Data: body })),
      );

    /*     return this.clinicalClient
          .getEncounter(practiceId, encounterId)
          .pipe(switchMap(s => this.clinicalClient.generateMedicalCertificateReport(practiceId, s))); */
  }

  getPrescriptionLegacy(practiceId: string, encounterId: string, prescriptionId: string) {
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider => this.getEncounterCommunicationBody(provider.PracticeNumber, prescriptionId)),
      map(body => ({ prescription: { Data: body } })),
    );

    // todo generate by prescriptionId, use method generatePrescriptionReportById
    /* return this.clinicalClient
      .getEncounter(practiceId, encounterId)
      .pipe(switchMap(encounter =>
        this.clinicalClient.generatePrescriptionReport(practiceId, encounter).pipe(
          map(prescription => ({ encounter, prescription }))
        )
      )); */
  }

  getPatientEncounterByVisit(practiceTenantId: string, providerId: string, patientId: string, patientVisit: PatientEventVo) {
    // load encounter from server and update local state
    return this.merakiService.getActiveEncounterForPatient(practiceTenantId, providerId, patientId, patientVisit).pipe(
      map(e => this.reorderEncounterLinesForEncounter(e)),
      tap(encounter => this.clinicalEncounterStore.addEncounter(patientId, encounter)),
      withLatestFrom(this.referenceDataService.symptoms$.pipe(map(s => s.filter(f => f.Type === 'default')))),
      tap(([encounter, defaultComplaints]) => {
        // Ensure default complaint types(section notes) are included in consult.
        // Causes issues when consult is started & continued on separate browsers/machines
        const defaults = defaultComplaints.filter(f => !encounter.Symptoms?.map(m => m.SymptomId).includes(f.SymptomId));
        defaults.forEach(c => this.addSymptom(practiceTenantId, patientId, c));
      }),
      map(([encounter, _]) => encounter),
    );
  }

  getPatientEncounter(practiceId: string, patientId: string) {
    // load encounter from server and update local state
    return this.providerService.waitingRoom$.pipe(
      take(1),
      map(s => s.find(d => d.PatientId === patientId && d.PatientEvent.PracticeId === practiceId)),
      filter(s => s && s.PatientEvent.Status !== 1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([s, provider]) => this.getPatientEncounterByVisit(
        provider.PracticeTenantId, provider.PracticeId,
        s.PatientEvent.Patient.PatientId, s.PatientEvent)),
      // we're already doing on getPatientEncounterByVisit func
      /* map(e => this.reorderEncounterLinesForEncounter(e)),
      tap(encounter => this.clinicalEncounterStore.addEncounter(patientId, encounter)) */
    );
  }

  checkPatientEncounter(practiceId: string, patientId: string): Observable<boolean> {
    // todo refactor this method to directly request data from server, waiting room might be not up to date
    // due to signalR issues
    return this.providerService.provider$.pipe(
      switchMap((provider) => this.patientsService.getActiveEncounter(provider.PracticeTenantId, practiceId, patientId, null)),
      map((patient) => patient != null),
    );
    /* return this.providerService.waitingRoom$.pipe(
      // take(1),
      map(s => s?.find(d => d.PatientId === patientId && d.PatientEvent.PracticeId === practiceId)),
      switchMap(s =>
        s && s.PatientEvent.Status !== 1 ? this.clinicalClient.getEncounterByVisitId(practiceId, s.PatientEvent.PatientEventId) : of(null)
      ),
      map(s => !!s)
    ); */
  }

  startOrGetCurrentPatientEncounter(practiceId: string, patientId: string, consultReason: ConsultReason = ConsultReason.NormalConsult) {
    // add patient to waiting room & create encounter, return obs to create instance in akita
    return this.providerService.getWaitingList().pipe(
      distinctUntilChanged(_.isEqual),
      take(1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([d, provider]) => {
        const waitingRoom = d?.find(room => room.PatientId === patientId && room.PatientEvent.PracticeId === practiceId);
        if (waitingRoom?.PatientEvent.Status === 2) {
          return this.getPatientEncounterByVisit(provider.PracticeTenantId, practiceId, patientId, waitingRoom.PatientEvent.PatientEventId)
            .pipe(
              // handle situation when for some reason we out of sync (visit in-progress but no encounter in firestore)
              catchError(s => of(null)),
              switchMap(s => s == null ? this.startEncounter(practiceId, patientId, consultReason, waitingRoom) : of(s)),
            );
        }
        return this.startEncounter(practiceId, patientId, consultReason, waitingRoom);
      }),
    );
  }

  cancelPatientVisit(tenantId: string, patientEvent: PatientEventVo) {
    return this.providerService.provider$.pipe(
      withLatestFrom(this.providerService.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiService.cancelPatientVisit(provider.PracticeNumber, provider.HPCSANumber, patientEvent.PatientEventXRef)
          : this.patientEventClient.cancelPatientVisit(tenantId, (
            {
              PatientEventId: patientEvent.PatientEventId,
              PatientEventXRef: patientEvent.PatientEventXRef,
              PracticeId: tenantId,
              Reason: 'Removed from waiting room',
              OriginatedSystem: 'ClinicianApp2.0',
            } as CancelPatientVisit)),
      ),
    );
  }

  cancelEncounterInProgress(practiceId: string, patientId: string) {
    // todo refactor to use patientVisitId from encounter as waitingRoom source can be not up to date due to signalR issues
    return this.providerService.waitingRoom$.pipe(
      take(1),
      map(s => s.find(d => d.PatientId === patientId && d.PatientEvent.PracticeId === practiceId)),
      map(waitingRoom =>
      ({
        PatientEventId: waitingRoom.PatientEvent.PatientEventId,
        PatientEventXRef: waitingRoom.PatientEvent.PatientEventXRef,
        PracticeId: practiceId,
        Reason: 'Removed from waiting room',
        OriginatedSystem: 'ClinicianApp2.0',
      } as CancelPatientVisit),
      ),
      withLatestFrom(this.providerService.provider$, this.providerService.isForge$),
      switchMap(([cancelVisit, provider, isForge]) =>
        (isForge
          ? this.merakiService.cancelPatientVisit(provider.PracticeNumber, provider.HPCSANumber, cancelVisit.PatientEventXRef)
          : this.patientEventClient.cancelPatientVisit(practiceId, cancelVisit)
        ).pipe(
          withLatestFrom(this.providerService.provider$, this.encounterInProgress$(practiceId, patientId).pipe(take(1))),
          switchTap(([response, provider, encounter]) => this.merakiService.updateEncounterStatus(provider.PracticeTenantId, encounter.EncounterId, 'Cancelled')),
          tap(s => this.providerService.removeVisitFromWaitingRoom(cancelVisit.PatientEventId)),
        ),
      ),
      tap(([result, provider, encounter]) => {
        if (result.Sucess) {
          this.clinicalEncounterStore.remove(patientId);
        }
      }),
      catchError((error) => {
        // if the above fails... there may be a bigger issue at play
        console.error('update status', error);
        this.clinicalEncounterStore.remove(patientId);
        return of(null);
      }),
      // switchMap(t => this.providerService.getWaitingList())
    );
  }

  private reorderPrescriptionLines(prescriptionLines: MedicationPrescriptionLine[]) {
    const fApplyNewLineNumbers = (acc: MedicationPrescriptionLine[], item: MedicationPrescriptionLine) => {
      const i = { ...item }; // create new object ()
      i.LineNum = acc.length + 1;
      return [...acc, i];
    };
    return prescriptionLines.reduce(fApplyNewLineNumbers, []);
  }

  private reorderEncounterLinesForEncounter(encounter: EncounterVo) {
    return {
      ...encounter,
      EncounterLineItems: {
        ...encounter.EncounterLineItems,
        LineItems: this.reorderEncounterLines(encounter.EncounterLineItems.LineItems),
      },
    } as EncounterVo;
  }

  private reorderEncounterLines(encounterLines: EncounterLineItemVo[]) {
    const fApplyNewLineNumbers = (acc: EncounterLineItemVo[], item: EncounterLineItemVo) => {
      const i = { ...item }; // create new object ()
      i.LineNum = acc.length + 1;
      return [...acc, i];
    };

    const fFilterLinesByTypes = (lines: EncounterLineItemVo[], types: string[]) =>
      lines.filter(line => types.some(t => t === line.LineType));

    const typeOrder = [['Procedure', 'Modifier'], ['Consumable'], ['Medicine']];

    const reordererdLines = typeOrder.flatMap(t => fFilterLinesByTypes(encounterLines, t)).reduce(fApplyNewLineNumbers, []);

    return _.sortBy(reordererdLines, e => e.LineNum);
  }

  private startEncounter(practiceId: string, patientId: string, consultReason: ConsultReason, visit?: PatientInWaitingRoomViewModel): Observable<EncounterStatusVo> {
    // create encounter & return obs to it
    // return this.waitingRoomClient
    const encounter$ = this.patientsService.patientById$(patientId).pipe(
      take(1),
      switchMap(patient => visit == null
        ? this.checkinAndInProgressPatientVisit(patient, practiceId, consultReason)
        : this.markAsInProgress(practiceId, visit.PatientEvent),
      ), // if visit not null move to in-progress
      withLatestFrom(this.providerService.provider$),
      switchMap(([patientVisit, provider]) => from(this.createEncounter(consultReason, patientVisit, provider))),
      switchMap(encounter => this.merakiService.startCapturingEncounter(encounter.PracticeId, patientId, encounter)),
    );

    return encounter$
      .pipe(
        // todo can be simplified and not required as it will created now on UI
        // switchMap(w => this.getPatientEncounterByVisit(practiceId, patientId, w.Data.PatientEventId)),
        map(e => this.reorderEncounterLinesForEncounter(e)),
        tap(encounter => this.clinicalEncounterStore.addEncounter(patientId, encounter)),
        withLatestFrom(this.referenceDataService.symptoms$.pipe(map(s => s.filter(f => f.Type === 'default')))),
        tap(([encounter, defaultComplaints]) => {
          // Ensure default complaint types(section notes) are included in consult.
          // Causes issues when consult is started & continued on separate browsers/machines
          const defaults = defaultComplaints.filter(f => !encounter.Symptoms?.map(m => m.SymptomId).includes(f.SymptomId));
          defaults.forEach(c => this.addSymptom(practiceId, patientId, c));
        }),
        map(([encounter, _]) => encounter),
        switchMap(s =>
          // load tokens for diagnosis without them (this diagnosis could be added by server side)
          forkJoin(s.Diagnosis?.filter(d => !d.DiagnosisToken).map(d => this.getDagnosisToken(d)) || []).pipe(
            defaultIfEmpty([]),
            tap(d => d.forEach(diagnosis => this.upsertDiagnosisVoToEncounter(practiceId, patientId, diagnosis))),
          ),
        ),
        withLatestFrom(this.referenceDataService.symptoms$.pipe(map(s => s.filter(f => f.Type === 'default')))),
        tap(([, defaultComplaints]) => defaultComplaints.forEach(c => this.addSymptom(practiceId, patientId, c))),
        switchMap(() => this.setEncounterDefaults(practiceId, patientId)),
        withLatestFrom(this.providerService.providerConfigurations$),
        tap(([, providerConfigurations]) => this.updateEncounterFromProviderConfigs(practiceId, patientId, providerConfigurations)),
        switchMap(() => this.clinicalEncounterQuery.patientInProgressEncounter$(patientId)),
        withLatestFrom(this.providerService.provider$),
        switchTap(([encounter, provider]) => {
          return this.merakiService.isKahunPractice(provider).pipe(switchTap(result => {
            return result ? this.renderKahunPatientSummary(provider.PracticeNumber, patientId) : of(null);
          }));
        }),
        take(1), // detach obs, we need only called once
      );
  }

  markAsInProgress(practiceId: string, visit: PatientEventVo) {
    return this.providerService.provider$.pipe(
      withLatestFrom(this.providerService.isForge$),
      switchMap(([provider, isForge]) =>
      (isForge
        ? this.merakiService.markVisitInProgress(provider.PracticeNumber, visit).pipe(
          switchMap(s => this.patientsService.patientById$(visit.Patient.PatientId)),
          map(d => ({ ...visit, Patient: d.details })),
        )
        : this.patientEventClient.markInProgress(practiceId, visit.PatientEventId, { PatientEventDetails: visit })
          .pipe(mapTo({ ...visit, Status: PatientEventVoStatus._2 }))
      )),
    );
  }

  checkinAndInProgressPatientVisit(patient: PatientStateModel, practiceId: string, consultReason: ConsultReason) {
    const id = uuidV4();
    const visit = {
      PatientEventId: guidByString(id, 5),
      PatientEventXRef: id,
      PracticeId: practiceId,
      Patient: patient.details,
      CheckedIn: true,
      ScheduledTime: moment().utc().toDate(),
      CheckInTime: moment().utc().toDate(),
    } as PatientEventVo;

    if (consultReason === ConsultReason.MedicalInsurance) {
      visit.Type = PatientEventType.MedicalInsuranceType;
      visit.MedicalInsurance = { PatientIdentityNo: patient.details.PatientDetails.IdentityNo };
    }

    return this.providerService.provider$.pipe(
      withLatestFrom(this.providerService.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiService.checkinInProgressPatient(provider.PracticeNumber, visit, provider).pipe(mapTo({
            ...visit,
            Status: PatientEventVoStatus._2,
          }))
          : this.patientEventClient.checkinPatient(practiceId, {
            PatientEventDetails: {
              ...visit,
              PatientEventXRef: null,
            },
          })
            .pipe(
              switchMap(s => this.markAsInProgress(practiceId, { ...visit, PatientEventXRef: null })),
            ),
      ),
    );
  }

  private async createEncounter(consultReason: string, visit: PatientEventVo, provider: ProviderConfigurationVo) {
    const encounter = {
      Status: 'Created',
      EncounterId: visit.PatientEventId,
      PracticeId: provider.PracticeTenantId,
      Diagnosis: [],
      EncounterLineItems: {
        EncounterHeader: {
          DateOfService: visit.CheckInTime,
          TariffCode: 'Tarrif',
          ClaimType: 'Normal',
          ClaimAuthorizationNumber: '',
          HospitalIndicator: false,
          PlaceOfService: EncounterHeaderVoPlaceOfService._0,
          Diagnosis: [],
        },
        LineItems: [],
      },
      ConsultReason: consultReason,
      PatientEventDetails: visit,
      SymptomQuestions: [],
      SymptomQuestionAnswers: [],
      Symptoms: [],
      MedicationsPrescriptions: [],
      AssistingDoctorName: null,
      AssistingDoctorPracticeNumber: null,
      ReferringDoctorName: null,
      ReferringDoctorPracticeNumber: null,
      BranchXRef: null,
      Provider: {
        CellphoneNumber: provider.CellphoneNumber,
        DispensingLicenseNumber: provider.DispensingDoctor,
        PracticeType: 'Single',
        TreatingDoctorPracticeNumber: provider.TreatingPracticeNumber,
        ContactNumber: provider.ContactNumber,
        DispensingDoctor: provider.DispensingDoctor,
        EmailAddress: provider.EmailAddress,
        PracticeName: provider.PracticeName,
        PracticeNumber: provider.PracticeNumber,
        SpecialityCode: provider.SpecialityCode,
        SpecialityDescription: provider.SpecialityDescription,
        FaxNumber: provider.FaxNumber,
        Qualification: provider.Qualification,
        HPCSANumber: provider.HPCSANumber,
        TreatingDoctorName: provider.TreatingDoctorName,
        IsLocumProvider: provider.IsLocumProvider,
        PhysicalAddress: provider.PhysicalAddress,
        PostalAddress: provider.PostalAddress,
        SubSpecialityCode: provider.SubSpecialityCode,
        TelephoneNumber: provider.TelephoneNumber,
      },
    } as EncounterStatusVo;

    let diagnosisToAdd = null;
    if (HospitalReasons.includes(consultReason)) {
      encounter.EncounterLineItems.EncounterHeader.PlaceOfService = EncounterHeaderVoPlaceOfService._21;
    } else if (encounter.ConsultReason === ConsultReason.TelehealthConsult ||
      VirtualVisitTypes.includes(encounter.ConsultReason)) {
      encounter.EncounterLineItems.EncounterHeader.PlaceOfService = EncounterHeaderVoPlaceOfService._2;
    } else if (encounter.ConsultReason === ConsultReason.VitalityAssessment) {
      diagnosisToAdd = 'Z00.0';
    } else if (encounter.ConsultReason === ConsultReason.ConditionsMedicationsDispense) {
      diagnosisToAdd = 'Z76.0';
    }

    if (encounter.PatientEventDetails.Type === PatientEventType.MedicalInsuranceType) {
      diagnosisToAdd = 'Z02.6';
      encounter.MedicalInsurance = encounter.PatientEventDetails.MedicalInsurance;

      const insurers = await this.providerService.loadMedicalInsurers$().toPromise();
      const insurer = insurers.find(s => s.Id == encounter.MedicalInsurance.MedicalInsurer?.Id);
      if (insurer != null) {
        encounter.MedicalInsurance.MedicalInsurer = insurer;
      }
      if (encounter.MedicalInsurance != null && encounter.MedicalInsurance.PatientIdentityNo != null) {
        encounter.MedicalInsurance.PatientIdentityNo = encounter.PatientEventDetails?.Patient?.PatientDetails?.IdentityNo;
      }
    } else {
      // todo add default procedure based on speciality rule
      // load speciality rule
      const specialityRule = await this.merakiService.getSpecialityRule(provider.PracticeId, provider.SpecialityCode).toPromise();

      let chargeCode = null;
      let defaultProcedureCode = specialityRule?.DefaultProcedureCode ?? '';

      if ((VirtualVisitTypes.includes(encounter.PatientEventDetails.Type) && defaultProcedureCode === '0190')
        || encounter.ConsultReason === ConsultReason.TelephonicConsult) {
        defaultProcedureCode = '0130';
        /* chargeCode = {
          ChargeCode: '0130',
          ChargeDesc: 'Telephone consultation (all hours)'
        }; */
      }

      if (encounter.ConsultReason === ConsultReason.VitalityAssessment) {
        const age = patientAge(encounter.PatientEventDetails.Patient.PatientDetails.DateOfBirth);
        defaultProcedureCode = age.unitType === 'years' && age.unit > 18 ? '705255001' : 'VKIDS';
        chargeCode = null;
      }

      if (encounter.ConsultReason === ConsultReason.ConditionsMedicationsDispense) {
        defaultProcedureCode = null;
      }

      if (encounter.ConsultReason === ConsultReason.VideoConsult) {
        defaultProcedureCode = 'VCONS';
        /* chargeCode = {
          ChargeCode: 'VCONS',
          ChargeDesc: 'Real time/Synchronous video consultation'
        }; */
      }

      if (consultReason === ConsultReason.AdhocPrescription) {
        defaultProcedureCode = '0132';
      } else if (consultReason === ConsultReason.AdhocSickNote) {
        const medicalCertificate = {
          MedicalCertificateId: uuidV4(),
          CreatedDate: new Date(),
          ExaminationDate: new Date(),
          FromDate: new Date(),
          ToDate: null,
          Provider: encounter.Provider,
          Patient: encounter.PatientEventDetails.Patient,
          Diagnoses: [],
        } as MedicalCertificate;
        encounter.MedicalCertificate = medicalCertificate;
      }

      if (specialityRule != null && chargeCode == null && !!defaultProcedureCode) {
        // search procedures from nova source for all (align with procedure change)
        try {
          const types = await this.referenceDataService.tarrifMedicalTypeCodes$.toPromise();
          const procedures = await this.getTariffCode(this.getTarrifMedicalType(types, provider.SpecialityCode), defaultProcedureCode);

          if (procedures && procedures.length > 0) {
            chargeCode = {
              ChargeCode: procedures[0].Code,
              ChargeDesc: procedures[0].Description
            };
          }
        } catch (e) {
          console.error("failed to load default procedure code", e);
        }
      }

      const excludeDefaultCode = this.providerService.getExcludeProcedureCode(provider.Options);
      // setting allow to ignore any pre-default logic, except adhoc prescription
      if (excludeDefaultCode && consultReason !== ConsultReason.AdhocPrescription) {
        chargeCode = null;
      }

      if (chargeCode != null) {
        encounter.EncounterLineItems.LineItems = [this.buildDefaultProcedureLine(chargeCode)];
      }

      const optionCode = encounter.PatientEventDetails.Patient?.PatientAccountDetails?.MedicalAidOptionCode;
      const isPpeEnabled = this.providerService.isPpeEnabled(provider.Options);
      const allowedConsultReason = AllowedPpeConsultReasons.includes(encounter.ConsultReason);

      if (isPpeEnabled && !excludeDefaultCode && allowedConsultReason && optionCode) {

        const ppeCodes = await this.merakiService.getPpeCodes(optionCode, encounter.Provider.SpecialityCode).toPromise();

        if (ppeCodes?.Tariffs?.length > 0) {
          const chargeCodes = [];
          for (const tariff of ppeCodes?.Tariffs) {
            const procedures = await this.searchProcedures(provider.PracticeId, tariff.TariffCode,
              encounter.EncounterLineItems.EncounterHeader.DateOfService).toPromise();
            if (procedures.length > 0) {
              chargeCodes.push({
                ChargeCode: procedures[0].Code,
                ChargeDesc: procedures[0].Description,
              });
            }
          }

          if (chargeCodes.length > 0) {
            encounter.EncounterLineItems.LineItems = [...encounter.EncounterLineItems.LineItems, ...chargeCodes.map(s => this.buildDefaultProcedureLine(s))];
          }
        }
      }
    }

    const result = diagnosisToAdd && await this.algoliaRestService.getDiagnosis(diagnosisToAdd) || null;
    if (result != null) {
      const diagnosis = {
        PrimaryIndicator: true,
        DiagnosisCode: result?.ICD10Code || null,
        DiagnosisDescription: result?.ICD10CodeDescription || null,
        ValidPrimary: result?.ValidAsPrimary === 1 || false,
        ValidSequelae: result?.ValidAsSequalae === 1 || false,
        Gender: result?.Gender || '',
        /* AgeRangeStart: result?.AgeRange, // todo get properly range
        AgeRangeEnd: result?.AgeRange, */
      } as DiagnosisVo2;

      encounter.EncounterLineItems.EncounterHeader.Diagnosis = [diagnosis];
      encounter.Diagnosis = [diagnosis];
      encounter.EncounterLineItems.LineItems = encounter.EncounterLineItems.LineItems.map(s => ({
        ...s,
        Diagnosis: [diagnosis],
      }) as EncounterLineItemVo);
    }

    encounter.EncounterType = this.getEncounterType(encounter);
    encounter.BranchXRef = encounter.PatientEventDetails.BranchXRef || null;
    encounter.RequiresFollowUp = provider.ManualPricingSchemes?.includes('*') || false;

    return encounter;
  }

  private buildDefaultProcedureLine(chargeCode: any): EncounterLineItemVo {
    return {
      ...chargeCode,
      LineType: 'Procedure',
      ChargeQuan: 1,
      ChargeUnits: 'Units',
      TotalIncVat: 0,
      UnitPrice: 0,
      TotalExcVat: 0,
      VatAmount: 0,
      Parameters: [],
    };
  }

  getEncounterType(encounter: EncounterStatusVo): string {
    if (encounter.PatientEventDetails.Type === PatientEventType.MedicalInsuranceType) {
      return EncounterType.MedicalInsurance;
    }

    if (encounter.PatientEventDetails.Type === PatientEventType.NoChargeType) {
      return EncounterType.ZeroInvoice;
    }

    if (encounter.PatientEventDetails.Patient.PatientAccountDetails.IsCashAccount) {
      return EncounterType.CashInvoice;
    }

    return EncounterType.MedicalAidInvoice;
  }


  getSmartTemplateStaticCategories(practiceId: string) {
    const cacheKey = `getSmartTemplateStaticCategories-${practiceId}`;
    const cache = this.cacheService.Get<StaticCategory[]>(cacheKey);
    if (!!cache) {
      return of(cache);
    } else {
      return this.clinicalNewClient.getSmartTemplatesStaticCategories(practiceId).pipe(
        tap(result => {
          this.cacheService.Set<StaticCategory[]>(cacheKey, result, { TTL: 1000 * 60 * 60 }); // cache for 60 minutes
        }),
      );
    }
  }

  getSmartTemplates(practiceId: string, patientId: string, token: string) {
    return this.clinicalEncounterQuery.ui.selectEntity(patientId).pipe(
      take(1),
      map(uiState => uiState?.diagnosisSuggestions || {}),
      switchMap(cache => {
        if (!!cache[token]) {
          // Cache hit
          const val = cache[token];
          return of(val);
        }
        // Cache miss
        return this.clinicalNewClient.searchSmartTemplates(practiceId, token).pipe(
          catchError(err => of(null)),
          filter(s => !!s),
          tap(templates => {
            this.clinicalEncounterStore.ui.update(patientId, uiState => {
              const newState = {
                ...uiState,
                diagnosisSuggestions: {
                  ...uiState.diagnosisSuggestions,
                  [token]: templates,
                },
              };
              return newState;
            });
          }),
        );
      }),
    );
  }

  getSmartTemplatesMedicines(practiceId: string, patientId: string, token: string): Observable<Medicines[]> {
    const cacheKey = `getSmartTemplatesStaticCategoryMedicines-${practiceId}-${token}`;
    const cache = this.cacheService.Get<Medicines[]>(cacheKey);
    if (!!cache) {
      return of(cache);
    } else {
      return this.clinicalNewClient.getSmartTemplatesStaticCategoryMedicines(practiceId, token).pipe(
        tap(result => {
          this.cacheService.Set<Medicines[]>(cacheKey, result, { TTL: 1000 * 60 * 5 }); // cache for 5 minutes
        }),
      );
    }
  }

  getSmartTemplatesConsumables(practiceId: string, patientId: string, token: string): Observable<Consumables[]> {
    const cacheKey = `getSmartTemplatesDynamicCategoryConsumables-${practiceId}-${token}`;
    const cache = this.cacheService.Get<Consumables[]>(cacheKey);
    if (!!cache) {
      return of(cache);
    } else {
      return this.clinicalNewClient.getSmartTemplatesStaticCategoryConsumables(practiceId, token).pipe(
        tap(result => {
          this.cacheService.Set<Consumables[]>(cacheKey, result, { TTL: 1000 * 60 * 5 }); // cache for 5 minutes
        }),
      );
    }
  }

  generateCommunicationReports(tenantId: string, encounterId: string, communications: CommunicationVo[]): Observable<RestApiResultOfString> {
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider => forkJoin([...communications.map(s => this.getEncounterCommunicationBody(provider.PracticeNumber, s.CommunicationId))])),
      switchMap(bodies => this.clinicalClient.getCommunicationReportsAsSinglePdf(tenantId, encounterId, bodies)),
    );
  }

  // getMedicine(practiceId: string, code: string) {
  //   return this.clinicalNewClient.searchMedicine(practiceId, code);
  // }

  getDosages(practiceId: string, atc: string, productForm: string, strength: string) {
    try {
      // Product strength can sometimes be an empty string. We need to explicity set it to 0 if it is not provided.
      strength = strength || '0';
      productForm = productForm || '?';
      // Ensure only numbers appear in strength, sometimes we get values of 40/60
      strength = strength.match(/^[0-9]+$/) ? strength : '0';
      return this.clinicalNewClient.getMedicineDosages(practiceId, atc, productForm, strength);
    } catch {
      return new Observable<Dosages>();
    }
  }

  getDosagesByMGIC(practiceId: string, mgic: string) {
    return this.clinicalNewClient.getMedicineDosagesByMGIC(practiceId, mgic);
  }

  upsertDiagnosisTokenToEncounter(tenantId: string, patientId: string, diagnosisToken: EncounterDiagnosisTokenItemVo) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      DiagnosisTokenItems: arrayUpsert(entity.DiagnosisTokenItems || [], diagnosisToken.DiagnosisToken, diagnosisToken, 'DiagnosisToken'),
    }));
  }

  removeDiagnosisTokenFromEncounter(tenantId: string, patientId: string, diagnosisToken: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      DiagnosisTokenItems: arrayRemove(entity.DiagnosisTokenItems, m => m.DiagnosisToken === diagnosisToken),
    }));
  }

  capturePatientFileNote(providerId: string, patientId: string, note: PatientFileNoteVo) {
    return this.clinicalNewClient.capturePatientFileNote(providerId, patientId, note);
  }

  upsertDiagnosisVoToEncounter(tenantId: string, patientId: string, diagnosis: DiagnosisVo2) {
    this.clinicalEncounterStore.update(patientId, entity => {
      const diagnoses = arrayUpsert(entity.Diagnosis || [], diagnosis.DiagnosisCode, diagnosis, 'DiagnosisCode');
      return {
        Diagnosis: diagnoses,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          EncounterHeader: {
            ...entity.EncounterLineItems.EncounterHeader,
            Diagnosis: diagnoses,
          },
          LineItems: entity.EncounterLineItems.LineItems.map(m => ({
            ...m,
            Diagnosis: arrayUpsert((m?.Diagnosis || []), diagnosis.DiagnosisCode, diagnosis, 'DiagnosisCode'),
          })),
        },
        MedicationsPrescriptions: entity.MedicationsPrescriptions.map(m => ({
          ...m,
          MedicationPrescriptionLines: m.MedicationPrescriptionLines.map(mp => ({
            ...mp,
            Diagnosis: arrayUpsert((mp?.Diagnosis || []), diagnosis.DiagnosisCode, diagnosis, 'DiagnosisCode'),
          })),
        })),
      };
    });
  }

  removeDiagnosisCodeFromEncounter(tenantId: string, patientId: string, diagnosisCode: string) {
    this.clinicalEncounterStore.update(patientId, entity => {
      const diagnoses = arrayRemove(entity.Diagnosis || [], m => m.DiagnosisCode === diagnosisCode);
      return {
        Diagnosis: diagnoses,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          EncounterHeader: {
            ...entity.EncounterLineItems.EncounterHeader,
            Diagnosis: diagnoses,
          },
          LineItems: entity.EncounterLineItems.LineItems.map(m => ({
            ...m,
            Diagnosis: m.Diagnosis.filter(f => f.DiagnosisCode !== diagnosisCode),
          })),
        },
        MedicationsPrescriptions: entity.MedicationsPrescriptions.map(m => ({
          ...m,
          MedicationPrescriptionLines: m.MedicationPrescriptionLines.map(mp => ({
            ...mp,
            Diagnosis: mp.Diagnosis.filter(f => f.DiagnosisCode !== diagnosisCode),
          })),
        })),
      };
    });
  }

  setDiagnosisAsPrimary(tenantId: string, patientId: string, diagnosisCode: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      Diagnosis: this.reorderDiagnosesForPrimary(entity.Diagnosis, diagnosisCode),
    }));
  }

  private reorderDiagnosesForPrimary(diagnoses: DiagnosisVo2[], primaryCode: string) {
    const sortedDiags = _.orderBy(diagnoses, d => (d.DiagnosisCode === primaryCode ? 0 : 1));
    return sortedDiags;
  }


  arrayUpsertItem<T>(array: T[], keyExtractor: KeyExtractor<T>, newItem: T): T[] {
    var result = [...array];
    const newItemKey = keyExtractor(newItem);
    const index = result.findIndex(item => keyExtractor(item) === newItemKey);

    if (index > -1) {
      result[index] = newItem;
    } else {
      result.push(newItem);
    }

    return result;
  }

  addProcedureToEncounter(tenantId: string, patientId: string, token: string, line: EncounterLineItemVo) {
    // exclude default procedures from incrementing quantity
    return this.providerService.specialityRule$.pipe(
      take(1),
      tap(specialityRule => {
        const defaultProcedure = specialityRule.DefaultProcedureCode;
        this.clinicalEncounterStore.update(patientId, entity =>
          this.reorderEncounterLinesForEncounter({
            ...entity,
            EncounterLineItems: {
              ...entity.EncounterLineItems,
              LineItems: this.arrayUpsertItem(
                entity.EncounterLineItems.LineItems,
                (item) => item.ChargeCode + item.LineType,
                {
                  LineType: 'Procedure',
                  LineNum: 99,
                  ChargeCode: line.ChargeCode,
                  ChargeDesc: line.ChargeDesc,
                  ChargeQuan: line.ChargeCode !== defaultProcedure ? this.getEncounterLineQuantitySum(entity, line) : 1,
                  UnitPrice: 0,
                  TotalExcVat: 0,
                  TotalIncVat: 0,
                  Amount: 0,
                  PriceOverride: false,
                  Diagnosis: entity.EncounterLineItems.EncounterHeader.Diagnosis,
                  Parameters: {
                    Token: token,
                  },
                } as EncounterLineItemVo,
                //'ChargeCode',
              ),
            },
          }),
        );
      }),
    );
  }

  private getEncounterLineQuantitySum(entity: EncounterVo, item: EncounterLineItemVo): number {
    const line =
      item.LineType === 'Procedure'
        ? entity.EncounterLineItems.LineItems.find(it => it.ChargeCode === item.ChargeCode && it.LineType === 'Procedure')
        : entity.EncounterLineItems.LineItems.find(it => it.NappiCode === item.NappiCode);

    const quantity = (line && line.ChargeQuan + item.ChargeQuan) || item.ChargeQuan || 1;

    return quantity;
  }

  addMedicineToEncounter(tenantId: string, patientId: string, medicine: EncounterLineItemVo) {
    this.clinicalEncounterStore.update(patientId, entity =>
      this.reorderEncounterLinesForEncounter({
        ...entity,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          LineItems: arrayUpsert(
            entity.EncounterLineItems.LineItems,
            medicine.NappiCode,
            {
              LineType: 'Medicine',
              LineNum: 99,
              ChargeCode: '0197',
              UnitPrice: 0,
              TotalExcVat: 0,
              TotalIncVat: 0,
              Amount: 0,
              PriceOverride: false,
              ...medicine,
              ChargeQuan: this.getEncounterLineQuantitySum(entity, medicine),
              Diagnosis: medicine.Diagnosis?.length && medicine.Diagnosis || entity.EncounterLineItems.EncounterHeader.Diagnosis,
            } as EncounterLineItemVo,
            'NappiCode',
          ),
        },
      }),
    );
  }

  updateProcedureOnEncounter(
    tenantId: string,
    patientId: string,
    procedureCode: string,
    lineNum: number,
    Amount: number,
    ChargeQuan: number,
  ) {
    this.clinicalEncounterStore.update(patientId, entity =>
      this.reorderEncounterLinesForEncounter({
        ...entity,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          LineItems: arrayUpdate(
            entity.EncounterLineItems.LineItems,
            line => line.ChargeCode === procedureCode && line.LineNum === lineNum,
            {
              Amount,
              ChargeQuan,
            },
          ),
        },
      }),
    );

    // let's re-price it now
    // this.priceEncounter(tenantId, patientId).subscribe();// no longer needed, is done in encounter summary
  }

  private getLinkedModifersForProcedure(encounterLines: EncounterLineItemVo[], procedureCode: string) {
    const lines = _.cloneDeep(encounterLines || []);
    const procedureLine = lines.find(line => line.LineType === 'Procedure' && line.ChargeCode === procedureCode);
    if (!procedureLine) {
      return [];
    }

    const modifierLines = lines
      .filter(line => line.LineNum > procedureLine.LineNum)
      .reduce(
        (a, line) => {
          if (a.complete) {
            return a;
          }
          if (line.LineType !== 'Modifier') {
            return { ...a, complete: true };
          }
          return { ...a, lines: arrayAdd(a.lines, line) };
        },
        { complete: false, lines: [] as EncounterLineItemVo[] },
      ).lines;

    return modifierLines;
  }

  removeAllLineItemsFromEncounter(tenantId: string, patientId: string) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity => {
          return {
            ...entity,
            EncounterLineItems: {
              ...entity.EncounterLineItems,
              LineItems: [],
            },
          };
        });
      }),
    );
  }

  removeProcedureFromEncounter(tenantId: string, patientId: string, procedureCode: string) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity => {
          const encounterLines = _.cloneDeep(entity.EncounterLineItems.LineItems);
          // list of linked modifiers for the procedure
          const modifierLinesToBeRemoved = this.getLinkedModifersForProcedure(encounterLines, procedureCode).map(line => line.LineNum);

          // get new array of amended lines
          const amendedLines = arrayRemove(
            encounterLines,
            line =>
              (line.LineType === 'Procedure' && line.ChargeCode === procedureCode) ||
              (line.LineType === 'Modifier' && modifierLinesToBeRemoved.includes(line.LineNum)),
          );

          return {
            ...entity,
            EncounterLineItems: {
              ...entity.EncounterLineItems,
              LineItems: this.reorderEncounterLines(amendedLines),
            },
          };
        });
      }),
      // switchMap(() => this.priceEncounter(tenantId, patientId))// no longer needed, is done in encounter summary
    );
  }

  removeModifierFromEncounter(tenantId: string, patientId: string, modifierCode: string, lineNum: number) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity => {
          const encounterLines = _.cloneDeep(entity.EncounterLineItems.LineItems);

          const amendedLines = arrayRemove(
            encounterLines,
            line => line.LineType === 'Modifier' && line.LineNum === lineNum && line.ChargeCode === modifierCode,
          );

          return {
            ...entity,
            EncounterLineItems: {
              ...entity.EncounterLineItems,
              LineItems: this.reorderEncounterLines(amendedLines),
            },
          };
        });
      }),
      // switchMap(() => this.priceEncounter(tenantId, patientId))// no longer needed, is done in encounter summary
    );
  }

  communicationLetter$(patientId: string, communicationId: string) {
    return this.encounterLettersQuery.selectEntity(patientId, e => e?.communications)
      .pipe(
        map(comm => comm?.find(d => d.Id === communicationId)),
      );
  }

  clearCommunicationLetter(patientId: string, communicationId: string) {
    this.encounterLettersStore.upsert(patientId, entity => ({
      PatientId: patientId,
      communications: arrayRemove(entity.communications || [], communicationId, 'Id'),
    }));
  }

  updateLetterBody(patientId: string, letter: Partial<Letter>) {
    this.encounterLettersStore.upsert(patientId, entity => ({
      PatientId: patientId,
      communications: arrayUpsert(entity.communications || [], letter.Id, letter, 'Id'),
    }));
  }

  addMedicineToPrescription$(tenantId: string, patientId: string, prescriptionLine: MedicationPrescriptionLine) {

    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(encounter => {
        // ensure we have created at least prescription created
        if (!encounter.MedicationsPrescriptions || encounter.MedicationsPrescriptions.length === 0) {
          this.clinicalEncounterStore.update(patientId, entity => ({
            MedicationsPrescriptions: [
              {
                MedicationPrescriptionId: uuidV4(),
                Patient: _.cloneDeep(entity.PatientEventDetails.Patient),
                Provider: _.cloneDeep(entity.Provider),
                IssuedDate: moment(new Date()).utc(true).toDate(),
                FutureDate: null,
                MedicationPrescriptionLines: [],
                Name: 'Prescription',
              },
            ],
          }));
        }
      }),
      withTransaction(encounter => {
        this.clinicalEncounterStore.update(patientId, entity => {
          if ((entity.MedicationsPrescriptions || []).length > 0) {
            const firstPrescription = entity.MedicationsPrescriptions[0];

            const updatedLines = this.reorderPrescriptionLines(
              arrayAdd(firstPrescription.MedicationPrescriptionLines, {
                ...prescriptionLine,
                Parameters: {
                  ...prescriptionLine.Parameters,
                  DosageDescription: prescriptionLine.Parameters?.DosageDescription || getFriendlyDosageDescription(
                    prescriptionLine.DosageUnits,
                    formDosageMeasurement(prescriptionLine.DosageType),
                    prescriptionLine.FrequencyUnits,
                    prescriptionLine.PeriodUnit,
                    prescriptionLine.PeriodType,
                    prescriptionLine.DurationUnit,
                    prescriptionLine.DurationType,
                    prescriptionLine?.Parameters?.RouteOfAdministration,
                  ),
                },
                Diagnosis: prescriptionLine.Diagnosis?.length && prescriptionLine.Diagnosis || encounter.EncounterLineItems.EncounterHeader.Diagnosis,
              }),
            );

            const updatedPrescription: MedicationPrescription = {
              ...firstPrescription,
              MedicationPrescriptionLines: updatedLines,
            };
            const prescriptions = arrayUpdate(
              entity.MedicationsPrescriptions,
              firstPrescription.MedicationPrescriptionId,
              updatedPrescription,
              'MedicationPrescriptionId',
            );

            this.clearCommunicationLetter(patientId, updatedPrescription.MedicationPrescriptionId);
            return { MedicationsPrescriptions: prescriptions, MedicationsPrescribed: null } as EncounterVo;
          }
          return null;
        });
      }),
    );
  }

  removeMedicineFromPrescription(tenantId: string, patientId: string, prescriptionId: string, lineNum: number) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      withTransaction(encounter => {
        const prescription = (encounter.MedicationsPrescriptions || []).find(p => p.MedicationPrescriptionId === prescriptionId);

        // find the prescription with the medicine
        if (prescription && (prescription.MedicationPrescriptionLines || []).some(line => line.LineNum === lineNum)) {
          // remove the medicine from the array
          const lines = this.reorderPrescriptionLines(
            arrayRemove(prescription.MedicationPrescriptionLines, row => row.LineNum === lineNum),
          );

          const updatedPrescription: MedicationPrescription = {
            ..._.cloneDeep(prescription),
            MedicationPrescriptionLines: lines,
          };

          const updatedPrescriptions: MedicationPrescription[] = arrayUpdate(
            encounter.MedicationsPrescriptions,
            prescription.MedicationPrescriptionId,
            updatedPrescription,
            'MedicationPrescriptionId',
          );

          if (lines.length === 0) {
            // remove related communication if prescription no longer contains any medicines
            this.removeClinicalCommunicationFromEncounter(tenantId, patientId, prescription.MedicationPrescriptionId);
          }

          // update the encounter with full prescriptions updated
          this.clinicalEncounterStore.update(patientId, { MedicationsPrescriptions: updatedPrescriptions });
          this.clearCommunicationLetter(patientId, prescription.MedicationPrescriptionId);
        }
      }),
    );
  }

  updateMedicationPrescription(
    tenantId: string,
    patientId: string,
    prescriptionId: string,
    medicationPrescription: Partial<MedicationPrescription>,
  ) {
    this.clinicalEncounterStore.update(patientId, entity => {
      const prescription = (entity.MedicationsPrescriptions || []).find(p => p.MedicationPrescriptionId === prescriptionId);

      const updatedPrescriptions: MedicationPrescription[] = arrayUpdate(
        entity.MedicationsPrescriptions,
        prescriptionId,
        { ...prescription, ...medicationPrescription },
        'MedicationPrescriptionId',
      );
      return { MedicationsPrescriptions: updatedPrescriptions };

    });
    this.clearCommunicationLetter(patientId, prescriptionId);
  }

  updateMedicineOnPrescription(
    tenantId: string,
    patientId: string,
    prescriptionId: string,
    lineNum: number,
    prescriptionLine: Partial<MedicationPrescriptionLine>,
  ) {
    this.clinicalEncounterStore.update(patientId, entity => {
      const prescription = (entity.MedicationsPrescriptions || []).find(p => p.MedicationPrescriptionId === prescriptionId);
      if (prescription?.MedicationPrescriptionLines?.length > 0) {
        const updatedLines = arrayUpdate(prescription.MedicationPrescriptionLines, lineNum, prescriptionLine, 'LineNum');
        const updatedPrescription: MedicationPrescription = {
          ...prescription,
          MedicationPrescriptionLines: updatedLines,
        };
        const updatedPrescriptions: MedicationPrescription[] = arrayUpdate(
          entity.MedicationsPrescriptions,
          prescriptionId,
          updatedPrescription,
          'MedicationPrescriptionId',
        );
        return { MedicationsPrescriptions: updatedPrescriptions };
      }
    });
    this.clearCommunicationLetter(patientId, prescriptionId);
  }

  moveMedicineToOtherPrescription(tenantId: string, patientId: string, prescriptionId: string, prescriptionLineNumber: number) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      map(encounter => {
        const prescriptions = encounter.MedicationsPrescriptions || [];
        const prescriptionSource = prescriptions.find(p => p.MedicationPrescriptionId === prescriptionId);
        if (!prescriptionSource) {
          return null;
        }
        return { encounter, prescriptionSource };
      }),
      filter(ret => !!ret),
      map(({ encounter, prescriptionSource }) => {
        const otherPrescription = (encounter.MedicationsPrescriptions || []).find(p => p.MedicationPrescriptionId !== prescriptionId);
        if (!otherPrescription) {
          const newPrescription = {
            MedicationPrescriptionId: uuidV4(),
            Patient: _.cloneDeep(encounter.PatientEventDetails.Patient),
            Provider: _.cloneDeep(encounter.Provider),
            IssuedDate: new Date(),
            FutureDate: null,
            MedicationPrescriptionLines: [],
            Name: 'Prescription',
          } as MedicationPrescription;
          return {
            encounter,
            prescriptionSource,
            prescriptionDestination: newPrescription,
          };
        }
        return { encounter, prescriptionSource, prescriptionDestination: otherPrescription };
      }),
      withTransaction(({ encounter, prescriptionSource, prescriptionDestination }) => {
        const lineToMove = _.cloneDeep(
          prescriptionSource.MedicationPrescriptionLines.find(line => line.LineNum === prescriptionLineNumber),
        );
        if (!lineToMove) {
          return;
        }

        const amendedSource: MedicationPrescription = {
          ..._.cloneDeep(prescriptionSource),
          // clear notes if prescription basically removed
          AdditionalNotes: prescriptionSource.MedicationPrescriptionLines.length == 1 ? null : prescriptionSource.AdditionalNotes || null,
          MedicationPrescriptionLines: this.reorderPrescriptionLines(
            arrayRemove(prescriptionSource.MedicationPrescriptionLines, line => line.LineNum === lineToMove.LineNum),
          ),
        };

        const amendedDestination: MedicationPrescription = {
          ..._.cloneDeep(prescriptionDestination),
          MedicationPrescriptionLines: this.reorderPrescriptionLines(
            arrayAdd(prescriptionDestination.MedicationPrescriptionLines, lineToMove),
          ),
        };

        const amendedPrescriptions = arrayUpsert(
          arrayUpsert(
            encounter.MedicationsPrescriptions,
            amendedDestination.MedicationPrescriptionId,
            amendedDestination,
            'MedicationPrescriptionId',
          ),
          amendedSource.MedicationPrescriptionId,
          amendedSource,
          'MedicationPrescriptionId',
        );

        if (amendedSource.MedicationPrescriptionLines.length === 0) {
          // remove related communication if prescription no longer contains any medicines
          this.removeClinicalCommunicationFromEncounter(tenantId, patientId, amendedSource.MedicationPrescriptionId);
        }

        this.clinicalEncounterStore.update(patientId, e => ({ MedicationsPrescriptions: amendedPrescriptions }));
        this.clearCommunicationLetter(patientId, amendedSource.MedicationPrescriptionId);
        this.clearCommunicationLetter(patientId, amendedDestination.MedicationPrescriptionId);
      }),
      switchMap(s => this.encounterInProgress$(tenantId, patientId)),
    );
  }

  addTemplateToEncounter$(encounter: EncounterVo, item: EncounterTemplateVo): Observable<boolean> {
    // todo as improvement apply akita transaction (currently due to rxjs nature it's hard to achieve)
    const diagnosisItems$ =
      item.EncounterLineItems?.EncounterHeader?.Diagnosis?.length > 0
        ? item.EncounterLineItems?.EncounterHeader?.Diagnosis?.map(diagnosis =>
          this.algoliaRestService.getAlgoliaDiagnosisToken(diagnosis.DiagnosisCode).pipe(
            map(DiagnosisToken => ({ ...diagnosis, DiagnosisToken } as DiagnosisVo2)),
            tap(diagnosisVo => this.upsertDiagnosisVoToEncounter(encounter.PracticeId, encounter.PatientId, diagnosisVo)),
          ),
        ) : [] as Observable<DiagnosisVo2>[];

    const lineItems$ = item.EncounterLineItems?.LineItems?.length > 0 ?
      item.EncounterLineItems?.LineItems?.map(line => {
        if (line.LineType === 'Procedure') {
          return this.addProcedureToEncounter(encounter.PracticeId, encounter.PatientId, null, line);
        } else if (line.LineType === 'Medicine') {
          return line.Parameters == null || line.Parameters?.MedicineType === 'Dispense'
            ? of({}).pipe(tap(s => this.addMedicineToEncounter(encounter.PracticeId, encounter.PatientId, line)))
            : this.addMedicineToPrescription$(encounter.PracticeId, encounter.PatientId, convertMedicineToPrescriptionLine(line));
        } else if (line.LineType === 'Modifier') {
          return of({}).pipe(
            tap(s => {
              const procedureCodes = [item.EncounterLineItems?.LineItems?.find(x => x.LineNum === line.LineNum - 1)?.ChargeCode];
              this.addModifierToProceduresOnEncounter(encounter.PracticeId, encounter.PatientId, line, procedureCodes);
            }));
        } else if (line.LineType === 'Consumable') {
          return this.addConsumableToEncounter(
            encounter.PracticeId,
            encounter.PatientId,
            null,
            null,
            line.NappiCode,
            '',
            line.ChargeDesc,
            line.ChargeQuan,
          );
        }
        return of();
      }) : [] as Observable<any>[];

    const prescriptions$ = item.MedicationPrescriptionLines?.length > 0 ?
      item.MedicationPrescriptionLines?.map(
        line => this.addMedicineToPrescription$(encounter.PracticeId, encounter.PatientId, line).pipe(mapTo(true)),
        /* this.algoliaRestService.getMedicineInformationByNappi(line.NappiCode)
        .pipe(
          switchMap(medicineInfo => this.getDosages(encounter.PracticeId, medicineInfo.ATC5Code,
            medicineInfo.hbProductUsageCode, medicineInfo.ProductStrength)),
          switchMap(result => this.addMedicineToPrescription(encounter.PracticeId, encounter.PatientId, line)),
          mapTo(true)
        ) */
      ) : [] as Observable<boolean>[];
    return concat(...diagnosisItems$, ...lineItems$, ...prescriptions$).pipe(
      toArray(), // allow to concat all obs results into one array and emit one value
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  addConsumableToEncounter(
    tenantId: string,
    patientId: string,
    categoryCode: string,
    subCategoryCode: string,
    code: string,
    token: string,
    description: string,
    quantity: number,
  ) {
    return this.providerService.specialityRule$.pipe(
      take(1),
      tap({
        next: specialityRule => {
          this.clinicalEncounterStore.update(patientId, entity => {
            let lineItems = entity.EncounterLineItems.LineItems;
            const line = entity.EncounterLineItems.LineItems.find(s => s.NappiCode === code);
            if (line != null) {
              lineItems = arrayUpdate(entity.EncounterLineItems.LineItems, l => l.NappiCode === code, {
                ...line,
                ChargeQuan: line.ChargeQuan + 1,
              });
            } else {
              lineItems = arrayAdd(entity.EncounterLineItems.LineItems, {
                LineType: 'Consumable',
                LineNum: 99, // add to end
                ChargeCode: specialityRule.ConsumableProcedureCode,
                ChargeDesc: description,
                NappiCode: code,
                ChargeQuan: quantity,
                UnitPrice: null,
                TotalExcVat: null,
                TotalIncVat: null,
                Amount: null,
                PriceOverride: false,
                Diagnosis: entity.EncounterLineItems.EncounterHeader.Diagnosis,
                Parameters: {
                  CategoryCode: categoryCode,
                  SubCategoryCode: subCategoryCode,
                  Token: `C-${specialityRule.ConsumableProcedureCode}-${subCategoryCode}`,
                },
              } as EncounterLineItemVo);
            }

            return this.reorderEncounterLinesForEncounter({
              ...entity,
              EncounterLineItems: {
                ...entity.EncounterLineItems,
                LineItems: lineItems,
              },
            });
          });
        },
      }),
      // switchMap(() => this.priceEncounter(tenantId, patientId))// no longer needed, is done in encounter summary
    );
  }

  updateMedicalInsurance(patientId: string, medicalInsurance: MedicalInsuranceVo) {
    this.clinicalEncounterStore.update(patientId, entity => ({ MedicalInsurance: medicalInsurance }));
  }

  updateConsumableOnEncounter(
    tenantId: string,
    patientId: string,
    diagnosisToken: string,
    code: string,
    quantity: number,
    amount: number,
    priceOverride: boolean,
  ) {
    return of(null).pipe(
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity =>
          this.reorderEncounterLinesForEncounter({
            ...entity,
            EncounterLineItems: {
              ...entity.EncounterLineItems,
              LineItems: arrayUpdate(entity.EncounterLineItems.LineItems, e => e.NappiCode === code, {
                ChargeQuan: quantity,
                Amount: amount,
                PriceOverride: priceOverride,
              } as EncounterLineItemVo),
            },
          }),
        );
      }),
      // switchMap(() => this.priceEncounter(tenantId, patientId))// no longer needed, is done in encounter summary
    );
  }

  removeConsumableFromEncounter(tenantId: string, patientId: string, code: string) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity => ({
          ...entity,
          EncounterLineItems: {
            ...entity.EncounterLineItems,
            LineItems: this.reorderEncounterLines(
              arrayRemove(entity.EncounterLineItems.LineItems, line => line.LineType === 'Consumable' && line.NappiCode === code),
            ),
          },
        }));
      }),
      // switchMap(() => this.priceEncounter(tenantId, patientId))// no longer needed, is done in encounter summary
    );
  }

  removeMedicineFromEncounter(tenantId: string, patientId: string, code: string, lineNum: number) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      tap(() => {
        this.clinicalEncounterStore.update(patientId, entity => ({
          ...entity,
          EncounterLineItems: {
            ...entity.EncounterLineItems,
            LineItems: this.reorderEncounterLines(
              arrayRemove(
                entity.EncounterLineItems.LineItems,
                line => line.LineType === 'Medicine' && line.NappiCode === code && line.LineNum === lineNum,
              ),
            ),
          },
        }));
      }),
    );
  }

  addToEncounter(tenantId: string, patientId: string, diagnosisToken: string, code: string, description: string, type: string) {
    this.clinicalEncounterStore.update(patientId, entity =>
      this.reorderEncounterLinesForEncounter({
        ...entity,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          LineItems: arrayAdd(entity.EncounterLineItems.LineItems, {
            LineType: type,
            ChargeCode: code,
            ChargeDesc: description,
            ChargeQuan: 1,
            UnitPrice: null,
            TotalExcVat: null,
            TotalIncVat: null,
            Amount: null,
            PriceOverride: false,
          } as EncounterLineItemVo),
        },
      }),
    );

    // let's price it now
    // this.priceEncounter(tenantId, patientId).subscribe();// no longer needed, is done in encounter summary
  }

  upadateToEncounter(tenantId: string, patientId: string, diagnosisToken: string, item: EncounterLineItemVo) {
    this.clinicalEncounterStore.update(patientId, entity =>
      this.reorderEncounterLinesForEncounter({
        ...entity,
        EncounterLineItems: {
          ...entity.EncounterLineItems,
          LineItems: arrayAdd(entity.EncounterLineItems.LineItems, {
            LineType: item.LineType,
            ChargeCode: item.ChargeCode,
            ChargeDesc: item.ChargeDesc,
            ChargeQuan: item.ChargeQuan,
            UnitPrice: 0,
            TotalExcVat: 0,
            TotalIncVat: 0,
            PriceOverride: false,
          } as EncounterLineItemVo),
        },
      }),
    );

    // let's price it now
    // this.priceEncounter(tenantId, patientId).subscribe();// no longer needed, is done in encounter summary
  }

  repeatScript(tenantId: string, patientId: string, medication: MedicationPrescription) {
    // return this.clinicalNewClient.addPatientPrescription(tenantId, patientId, medication);
    return this.providerService.communicationTemplates$.pipe(
      take(1),
      map(templates => templates.find(x => x.TemplateIdentifier === 'Prescription' && x.Locale === 'EN')),
      switchMap(template => this.clinicalNewClient.renderAdhocPrescriptionPOST(tenantId, patientId, medication.MedicationPrescriptionId, {
        MedicationPrescription: medication,
        Template: template,
      })),
      withLatestFrom(this.providerService.provider$),
      // 1. save html to storage
      switchMap(([content, provider]) => this.merakiService.updateHtmlToStorage(provider.PracticeNumber, 'Prescriptions', medication.MedicationPrescriptionId, content.Data)
        .pipe(mapTo(content))),
      // 2. add to timeline
      switchMap(content =>
        this.clinicalNewClient.addPatientTimelineEntries(tenantId, patientId,
          medication.MedicationPrescriptionLines.map(l => ({
            Type: 'AdhocPrescriptionLine',
            ParentReferenceId: medication.MedicationPrescriptionId,
            Code: l.NappiCode,
            Description: medication.MedicationPrescriptionId,
            DateTime: medication.IssuedDate,
            Narrative: `℞: ${l.Description}`,
            PatientId: patientId,
            ParentLineNum: 0,
          })),
        ).pipe(mapTo(content)),
      ),
    );
  }

  capturePatientClinicalMetrics(tenantId: string, patientId: string, metrics: ClinicalMetricVo[]) {
    // return this.clinicalPatientClient.capturePatientClinicalMetrics(tenantId, patientId, metrics);
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider =>
        this.patientsService.patientCapturedMetricsById$(provider.PracticeId, patientId).pipe(
          take(1),
          switchMap(allMetrics => this.merakiService.saveClinicalMetrics(provider.PracticeTenantId, patientId, allMetrics, metrics)),
          // update patient timeline with clinical metrics
          // todo confirm what to do when delete
          switchMap(result => {
            const entries = result.map((c, index) => this.patientsService.clinicalMetricToTimelineEntry(c, index, patientId, provider));
            return (entries.length > 0)
              ? this.clinicalNewClient.addPatientTimelineEntries(provider.PracticeId, patientId, entries).pipe(
                switchMap(s => this.patientsService.loadPatientCapturedMetrics(patientId)),
              )
              : of(null);
          }),
        ),
      ),
      mapTo({ Sucess: true } as RestApiResultOfString),
      catchError(s => of({ Sucess: false, ResponseMessage: 'Failed to save metrics' } as RestApiResultOfString)),
    );
  }


  removeBabyPatientClinicalMetrics(patientId: string, referenceId: string) {
    // return this.clinicalPatientClient.removePatientClinicalMetrics(tenantId, patientId, metrics);
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider =>
        this.patientsService.patientCapturedMetricsById$(provider.PracticeId, patientId).pipe(
          take(1),
          switchMap(allMetrics => this.merakiService.removePatientClinicalMetrics(provider.PracticeTenantId, patientId,
            allMetrics, referenceId, babyMetricTypes)
            .pipe(s => {
              const metricsForDelete = _.chain(allMetrics).filter(s => s.ReferenceId == referenceId && babyMetricTypes.includes(s.Name))
                .map((s, index) => this.patientsService.clinicalMetricToTimelineEntry(s, index, patientId, provider)).value();
              return this.clinicalNewClient.removePatientTimelineEntries(provider.PracticeId, patientId, metricsForDelete);
            }))),
      ));
  }

  getAllPatientClinicalMetrics(tenantId: string, patientId: string) {
    // return this.clinicalPatientClient.getAllPatientClinicalMetrics(tenantId, patientId);
    return this.patientsService.loadPatientCapturedMetrics(patientId);
  }

  removeLineFromEncounter(tenantId: string, patientId: string, lineNum: number) {
    const encounterLineType = (this.clinicalEncounterQuery.getEntity(patientId).EncounterLineItems.LineItems || []).find(
      line => line.LineNum === lineNum,
    );

    if (!encounterLineType) {
      return EMPTY;
    }

    switch (encounterLineType.LineType) {
      case 'Procedure':
        return this.removeProcedureFromEncounter(tenantId, patientId, encounterLineType.ChargeCode);
      case 'Consumable':
        return this.removeConsumableFromEncounter(tenantId, patientId, encounterLineType.NappiCode);
      case 'Modifier':
        return this.removeModifierFromEncounter(tenantId, patientId, encounterLineType.ChargeCode, encounterLineType.LineNum);
      case 'Medicine':
        return this.removeMedicineFromEncounter(tenantId, patientId, encounterLineType.NappiCode, lineNum);

      default:
        throw new Error('Unknown line type');
    }
  }

  sickNoteRequireRefresh$(tenantId, patientId): Observable<boolean> {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      map(encounter => encounter.MedicalCertificate),
      take(1),
      filter(s => !!s.FromDate && !!s.ToDate && (!s.Accompanied || (!!s.AccompaniedFromDate && !!s.AccompaniedToDate && !!s.AccompaniedBy))), // used as validation if something is missing
      withLatestFrom(this.patientsService.patientUIState$(patientId).pipe(map(state => state.prevSickNote))),
      map(
        ([sickNoteDetails, prevSickNote]) =>
          !prevSickNote?.htmlBody ||
          !_.isEqual(
            sickNoteDetails?.Diagnoses.map(d => d.DiagnosisCode),
            prevSickNote?.medicalCertificate?.Diagnoses.map(d => d.DiagnosisCode),
          ) ||
          !_.isEqual(sickNoteDetails?.FromDate.toDateString(), prevSickNote?.medicalCertificate?.FromDate?.toDateString()) ||
          !_.isEqual(sickNoteDetails?.ToDate.toDateString(), prevSickNote?.medicalCertificate?.ToDate?.toDateString()) ||
          !_.isEqual(sickNoteDetails?.ReturnDate?.toDateString(), prevSickNote?.medicalCertificate?.ReturnDate?.toDateString()) ||
          !_.isEqual(sickNoteDetails?.IncludeSignature, prevSickNote?.medicalCertificate?.IncludeSignature) ||
          !_.isEqual(sickNoteDetails?.AsInformed, prevSickNote?.medicalCertificate?.AsInformed) ||
          !_.isEqual(sickNoteDetails?.AccompaniedBy, prevSickNote?.medicalCertificate?.AccompaniedBy) ||
          !_.isEqual(sickNoteDetails?.AccompaniedFromDate?.toDateString(), prevSickNote?.medicalCertificate?.AccompaniedFromDate?.toDateString()) ||
          !_.isEqual(sickNoteDetails?.AccompaniedToDate?.toDateString(), prevSickNote?.medicalCertificate?.AccompaniedToDate?.toDateString()) ||
          !_.isEqual(sickNoteDetails?.FollowupDate?.toDateString(), prevSickNote?.medicalCertificate?.FollowupDate?.toDateString()),
      ),
    );
  }

  generateMedicalCertificate$(tenantId: string, patientId: string): Observable<string> {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      switchMap(encounter =>
        this.createCommunication(
          encounter.PatientEventDetails.PracticeId,
          encounter.PatientEventDetails.Patient.PatientId,
          encounter.MedicalCertificate.MedicalCertificateId,
          encounter,
        ).pipe(map(result => ({ encounter, result }))),
      ),
      map(data => {
        this.patientsService.updateSickNoteUIState(
          data.encounter.PatientEventDetails.Patient.PatientId,
          data.encounter.MedicalCertificate,
          data.result,
        );
        return data.result;
      }),
    );
  }

  updateCommunicationsBranch(tenantId: string, patientId: string): Observable<any[]> {
    // todo refactor to take into account new prescription logic
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      switchMap(encounter => {
        // don't check prescriptions, because it has own regeneration logic, even for branch changes
        const generateSickNote = !!encounter.MedicalCertificate?.ToDate;
        const generateReferrals = encounter.Communications.filter(x => x.Type === 'Referrals').length > 0;
        const communications$ = [];

        if (generateSickNote) {
          communications$.push(
            this.createCommunication(
              encounter.PatientEventDetails.PracticeId,
              encounter.PatientEventDetails.Patient.PatientId,
              encounter.MedicalCertificate.MedicalCertificateId,
              encounter,
            ).pipe(
              tap(res =>
                this.patientsService.updateSickNoteUIState(
                  encounter.PatientEventDetails.Patient.PatientId,
                  encounter.MedicalCertificate,
                  res,
                ),
              ),
            ),
          );
        }

        if (generateReferrals) {
          encounter.Communications.filter(x => x.Type === 'Referrals').forEach(referral => {
            communications$.push(
              this.createCommunication(
                encounter.PatientEventDetails.PracticeId,
                encounter.PatientEventDetails.Patient.PatientId,
                referral.CommunicationId,
                encounter,
              ),
            );
          });
        }
        return communications$.length > 0 ? forkJoin(communications$) : of([]);
      }),
    );
  }

  getSickNoteHtmlUi(tenantId: string, patientId: string): Observable<string> {
    return this.patientsService.patientUIState$(patientId).pipe(
      map(state => state.prevSickNote?.htmlBody),
      take(1),
    );
  }

  upsetMedicalCertificateToEncounter(tenantId: string, patientId: string, medicalCertificate: MedicalCertificate) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      MedicalCertificate: medicalCertificate,
    }));
  }

  addMedicationPrescription(tenantId: string, patientId: string, medicationPrescription: MedicationPrescription) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      MedicationsPrescriptions: arrayAdd(entity.MedicationsPrescriptions, medicationPrescription),
    }));
  }

  createCommunication(tenantId: string, patientId: string, communicationId: string, encounter: EncounterVo) {
    const encounterUpdated = this.removeDosageInstructionFromEncounter(encounter);
    const templateId = encounter.Communications.find(x => x.CommunicationId === communicationId).CommunicationTemplateId;

    return this.providerService.getPracticeBranches(tenantId).pipe(
      map(branches => branches.find(b => b.BranchXRef == encounter.BranchXRef)),
      withLatestFrom(this.providerService.communicationTemplates$),
      take(1),
      map(([branch, templates]) =>
      ({
        branch: branch,
        template: templates.find(x => x.TemplateId === templateId),
        baseTemplate: templates.find(s => s.TemplateIdentifier === 'CommonTemplate'),
      })),
      switchMap(({ branch, template, baseTemplate }) => {
        // depending on report type load extra information
        if (template.TemplateIdentifier === 'Prescription') {

          return this.patientsService.getPatientAllergiesMedications$(tenantId, patientId)
            .pipe(
              map(allergies => ({ Template: template, PatientAllergies: allergies, Branch: branch }))
            )
        }

        if (template.TemplateType == 'custom-letter') {
          return this.patientsService.getPatientAllergiesMedications$(tenantId, patientId)
            .pipe(
              map(allergies => ({
                Template: template, BaseTemplate: baseTemplate, PatientAllergies: allergies, Branch: branch, Encounter: encounterUpdated
              })),
              map(request => this.setCustomLetterPlaceholders(request))
            )
        }

        if (template.TemplateType == 'Referrals') {

          return forkJoin([
            of({ Template: template, Branch: branch }),
            this.patientsService.getPatientClinicalNotesById$(tenantId, patientId).pipe(
              take(1),
              map(s => ({ ClinicalNote: s?.Note })),
            ),
            this.patientsService.patientAllergiesById$(tenantId, patientId).pipe(
              take(1),
              map(s => ({
                PatientAllergies: s.PatientAllergies.map(d => d.Allergen.Description),
                PatientAllergiesNote: s.Other,
              })),
            ),
            this.patientsService.patientConditionsById$(tenantId, patientId).pipe(
              take(1),
              map(s => ({ ChronicConditions: s.Conditions?.map(s => s.Condition.Name) })),
            ),
            this.patientsService.getPatientSurgicalHistory$(tenantId, patientId).pipe(
              take(1),
              map(s => ({ SurgicalHistory: s })),
            ),
          ]).pipe(map(items => Object.assign({}, ...items)));
        }

        return of({ Template: template, BaseTemplate: baseTemplate, Branch: branch });
      }),
      switchMap(request => this.clinicalNewClient.renderEncounterCommunicationPOST(tenantId, patientId, communicationId,
        {
          ...request,
          Encounter: encounterUpdated,
        }),
      ),
      map(result => result.Data),
      switchMap(data => this.updateCommunication(encounter.EncounterId, communicationId, { Content: data }).pipe(mapTo(data))),
    );

    /* return this.clinicalClient.createCommunication(tenantId, patientId, communicationId, encounterUpdated)
      .pipe(
        map(s => s.Data)
      ); */
  }

  setCustomLetterPlaceholders(request: CommunicationRenderRequest): CommunicationRenderRequest {
    // please note done here to skip updating florence backend, older placeholders still filled on backend
    // see full list of possible placeholders here ReferenceDataService.SpecialPlaceholders
    let body = request.Template.TemplateBody;
    body = body.replace(new RegExp("{{PatientEmailAddress}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientDetails.EmailAddress ?? "");
    body = body.replace(new RegExp("{{PatientMobileNumber}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientDetails.ContactNo ?? "");
    body = body.replace(new RegExp("{{MembershipNumber}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidMembershipNumber ?? "");
    body = body.replace(new RegExp("{{AccountNo}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientAccountDetails.AccountNo ?? "");
    body = body.replace(new RegExp("{{MedicalAidName}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidName ?? "");
    body = body.replace(new RegExp("{{MedicalAidPlan}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlan ?? "");
    body = body.replace(new RegExp("{{MedicalAidOption}}", 'g'), request.Encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlanOption ?? "");
    body = body.replace(new RegExp("{{PatientAllergies}}", 'g'), request.PatientAllergies.join(", "));
    body = body.replace(new RegExp("{{ICD10Codes}}", 'g'), request.Encounter.EncounterLineItems.EncounterHeader.Diagnosis.map(s => s.DiagnosisCode).join(", "));
    body = body.replace(new RegExp("{{ReportDate}}", 'g'), moment(/* request.Encounter.EncounterLineItems.EncounterHeader.DateOfService */).format('YYYY-MM-DD'));
    return ({ ...request, Template: { ...request.Template, TemplateBody: body } });

  }

  updateClinicalCommunicationToEncounter(tenantId: string, patientId: string, communication: CommunicationVo) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      Communications: arrayUpdate(
        encounter.Communications,
        entity => entity.CommunicationId === communication.CommunicationId,
        communication,
      ),
    }));
  }

  @transaction() // update encounter in one transaction to minimize no. of separate emissions
  upsertCommunicationPrescriptionToEncounter(
    templates: CommunicationTemplateVo[],
    encounter: EncounterVo,
    medicationPrescriptionId: string,
    includeSignature: boolean,
    IsOriginalScript: boolean,
    excludeDate: boolean,
    additionalNotes: string,
  ): string {
    const template = templates.find(t => t.TemplateIdentifier === 'Prescription' && t.PracticeId === encounter.PracticeId);
    const templateId = !template ? templates.find(t => t.TemplateIdentifier === 'Prescription').TemplateId : template.TemplateId;

    const prescriptionPosition = encounter.MedicationsPrescriptions.findIndex(s => s.MedicationPrescriptionId === medicationPrescriptionId);
    const newCommunication = {
      CommunicationId: medicationPrescriptionId,
      PatientId: encounter.PatientEventDetails.Patient.PatientId,
      EncounterId: encounter.EncounterId,
      CommunicationTemplateId: templateId,
      IncludeSignature: includeSignature,
      Type: 'Prescription',
      Title: `Prescription ${prescriptionPosition + 1}`,
    } as CommunicationVo;

    this.updateMedicationPrescription(encounter.PracticeId, newCommunication.PatientId,
      medicationPrescriptionId, ({
        AdditionalNotes: additionalNotes || null, ExcludeReportDate: excludeDate || null,
        IsOriginalScript: IsOriginalScript || false,
      }));

    this.upsertClinicalCommunicationToEncounter(
      encounter.PatientEventDetails.PracticeId,
      newCommunication.PatientId,
      newCommunication,
    );
    return newCommunication.CommunicationId;
  }

  /**
   * Sync actual prescriptions with communication report
   */
  syncPrescriptionCommunications$(tenantId: string, patientId: string) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      withLatestFrom(
        this.providerService.IncludesDigitalSignature$,
        this.providerService.communicationTemplates$),
      tap(([encounter, signature, templates]) =>
        encounter.MedicationsPrescriptions
          ?.filter(p => p.MedicationPrescriptionLines?.length > 0)
          .forEach(prescription => {
            const communication = encounter.Communications.find(com => com.CommunicationId === prescription.MedicationPrescriptionId);
            if (!communication) {
              this.upsertCommunicationPrescriptionToEncounter(templates, encounter,
                prescription.MedicationPrescriptionId, signature, prescription.IsOriginalScript, prescription.ExcludeReportDate, prescription.AdditionalNotes);
            }
          }),
      ),
    );
  }

  /**
   * Generate prescriptions reports, heavy call to server, use wisely
   */
  generatePrescriptionReport$(tenantId: string, patientId: string): Observable<string[]> {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      take(1),
      switchMap(encounter => {
        const prescriptions = encounter.Communications.filter(c => c.Type === 'Prescription');
        return prescriptions.length > 0 ? forkJoin(prescriptions.map((communication, index) =>
          this.communicationLetter$(encounter.PatientId, communication.CommunicationId).pipe(
            map(letter => ({ letter, id: communication.CommunicationId })),
            take(1),
            switchMap(response => !response.letter?.BodyHtml &&
              this.createCommunication(encounter.PatientEventDetails.PracticeId, encounter.PatientId,
                response.id, encounter) || of(response.letter.BodyHtml),
            ),
            tap(html => this.updateLetterBody(encounter.PatientId,
              {
                Id: communication.CommunicationId,
                BodyHtml: html,
              })),
            // trick to avoid concurrent issue (happens if we have 2 prescriptions and trying to create them in parallel)
            delay(index * 10),
          ),
        )) : of([]);
      },
      ),
    );
  }

  getRenderReferralTemplate(practiceId: string, encounterId: string, communicationId: string) {
    // return this.clinicalClient.getRenderReferralTemplate(practiceId, encounterId, templateId, 0);
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider => this.getEncounterCommunicationBody(provider.PracticeNumber, communicationId)),
    );
  }

  getEncounterCommunicationBody(bpn: string, communicationId: string) {
    return this.merakiService.getHtmlBodyFromStorage(bpn, 'EncounterCommunications', communicationId)
      .pipe(
        catchError(s => of(null)),
        switchMap(s => !!s ? of(s) : this.merakiService.getHtmlBodyFromStorage('unknown', 'EncounterCommunications', communicationId)),
      );
  }

  removeMedicationPrescription(tenantId: string, patientId: string, medicationPrescriptionId: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      MedicationsPrescriptions: arrayRemove(entity.MedicationsPrescriptions, medicationPrescriptionId, 'MedicationPrescriptionId'),
    }));
  }

  updateMedicationPrescriptions(tenantId: string, patientId: string, medicationPrescriptions: MedicationPrescription[]) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      MedicationsPrescriptions: medicationPrescriptions,
    }));
  }

  updatePlanOutcomesToEncounter(practiceId: string, patientId: string, planOutcomes: EncounterPlanOutcomeVo[]) {
    this.clinicalEncounterStore.update(patientId, entity => ({ EncounterPlanOutcomes: planOutcomes }));
  }

  updateVisitOutcomeToEncounter(practiceId: string, patientId: string, followUp: EncounterFollowUpVo) {
    this.clinicalEncounterStore.update(patientId, entity => ({ EncounterFollowUp: followUp }));
  }

  updateDiagramsToEncounter(patientId: string, encounterDiagrams: EncounterDiagramVo[]) {
    this.clinicalEncounterStore.update(patientId, entity => ({ EncounterDiagrams: encounterDiagrams }));
  }

  upsertClinicalCommunicationToEncounter(tenantId: string, patientId: string, communication: CommunicationVo) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      Communications: arrayUpsert(entity.Communications || [], communication.CommunicationId, communication, 'CommunicationId'),
    }));
  }

  updateCommunication(
    encounterId: string,
    communicationId: string,
    body: UpdateEncounterCommunication,
  ) {
    // return this.clinicalClient.updateCommunication(practiceid, encounterId, communicationId, indexId, body);
    return this.providerService.provider$.pipe(
      take(1),
      // upload to storage, call cloud function to save practices/000222/communications/id, + meta data,
      switchMap(provider => this.merakiService.updateHtmlToStorage(provider.PracticeNumber, 'EncounterCommunications', communicationId, body.Content)),
    );
  }

  saveEncounter(providerId: string, patientId: string) {
    return this.encounterInProgress$(providerId, patientId).pipe(
      take(1),
      map(encounter => this.fixEncounterObject(encounter)),
      withLatestFrom(this.providerService.provider$),
      switchMap(([encounter, provider]) => this.merakiService.checkIfEncounterStillActive(provider.PracticeTenantId, patientId, encounter.EncounterId).pipe(
        switchMap(isActive => !isActive
          ? of(null)
          : this.merakiService.saveEncounter(providerId, encounter.EncounterId, encounter).pipe(
            withLatestFrom(this.providerService.isForge$),
            switchTap(([result, isForge]) =>
              isForge
                ? of(result)
                : this.patientEventClient.updatePatientEvent(encounter.PatientEventDetails.PracticeId, ({
                  PatientEventDetails: {
                    ...encounter.PatientEventDetails,
                    BranchXRef: encounter.BranchXRef || encounter.PatientEventDetails.BranchXRef,
                    // todo
                    // BranchName:
                    MedicalInsurance: encounter.MedicalInsurance || encounter.PatientEventDetails.MedicalInsurance,
                  },
                })).pipe(catchError(s => of(null))), // ignore if florence won't be succesful (low priority call)
            ),
            map(([result, _]) => result),
          ))),//throwError("Consultation already completed."))) ..
      ),
    );
  }

  fixEncounterObject(encounter: EncounterVo): EncounterVo {
    // temp fix to resolve serialization issue, can be removed after few releases
    if (encounter.EncounterFollowUp?.FollowUpReasons?.length > 0) {
      if (typeof encounter.EncounterFollowUp.FollowUpReasons[0] !== 'string') {
        const complex = encounter.EncounterFollowUp?.FollowUpReasons[0] as any;
        return ({
          ...encounter, EncounterFollowUp: ({ ...encounter.EncounterFollowUp, FollowUpReasons: [complex?.id] }),
        });
      }
    }

    const encounterUpdated = this.removeDosageInstructionFromEncounter(encounter);

    return encounterUpdated;
  }

  completeEncounter(providerId: string, patientId: string): Observable<RestApiResultOfCompleteEncounterResult> {
    return this.encounterInProgress$(providerId, patientId).pipe(
      take(1),
      map(encounter => ({ ...encounter, ClinicalMetrics: encounter.ClinicalMetrics?.filter(s => !!s.Value) })),
      map(encounter => this.fixEncounterObject(encounter)),
      withLatestFrom(this.patientsService.patientById$(patientId).pipe(take(1))),
      map(([encounter, patient]) => ({
        ...encounter,
        PatientEventDetails: {
          ...encounter.PatientEventDetails,
          // refresh patient details to handle situation when info was outdated at the beginning when encounter created
          Patient: patient.details || encounter.PatientEventDetails.Patient,
        },
      })),
      withLatestFrom(this.providerService.provider$, this.providerService.isForge$),
      switchMap(([encounter, provider, isForge]) => {

        const forgePipeline = from(this.requireManualProcessing(encounter)).pipe(
          switchMap(requireManualProcessing =>
            this.merakiService.saveEncounter(providerId, patientId, encounter)
              .pipe(
                switchMap(s => this.merakiService.completeEncounter(provider.PracticeNumber, encounter, requireManualProcessing)),
                switchTap(s => this.patientsService.addPatientTimelineEventsForEncounter(providerId, patientId, encounter)),
                switchTap(s => this.merakiService.completeVisit(provider.PracticeNumber, encounter.PatientEventDetails)),
                switchTap(s => encounter.EncounterFollowUp?.FollowUpRequired &&
                  this.patientsService.scheduleVisitFollowup(providerId,
                    patientId, {
                    FollowUpReasons: encounter.EncounterFollowUp.FollowUpReasons,
                    EncounterId: encounter.EncounterId,
                    PatientCommunication: {
                      DoctorName: encounter.Provider.TreatingDoctorName,
                      FollowUpDate: encounter.EncounterFollowUp.DueDateForFollowUp,
                      PatientCellphone: encounter.EncounterFollowUp.FollowUpPatientCellphone,
                      PatientDOB: encounter.PatientEventDetails.Patient.PatientDetails.DateOfBirth,
                      PatientName: encounter.PatientEventDetails.Patient.PatientDetails.FirstName,
                      PatientSurname: encounter.PatientEventDetails.Patient.PatientDetails.Surname,
                      PracticeId: encounter.PracticeId,
                      PatientId: patientId,
                      CommunicationText: encounter.EncounterFollowUp.FollowUpMessage,
                    },
                  }
                  ) || of(null)),
              ),
          ),
        );

        return (isForge ? forgePipeline : this.clinicalClient.completeEncounter(providerId, encounter.EncounterId, {
          EncounterVo: {
            ...encounter,
            ClinicalMetrics: [],
          },
        })).pipe(
          map(result => ({ result, encounter, provider })),
        );
      }),
      switchTap(({
        result,
        encounter,
        provider,
      }) => this.merakiService.updateEncounterStatus(provider.PracticeTenantId, encounter.EncounterId, 'Completed').pipe(
        catchError((err) => {
          console.error('update status: ' + err);
          this.appInsightsService.trackException(err, 'updateEncounterStatus', { encounterId: encounter.EncounterId });
          // updating status is not critical for now, as this will do florence backend, so we can say success:true
          return of({ Sucess: true });
        })),
      ),
      switchTap(({
        result,
        encounter,
        provider,
      }) => encounter.ClinicalMetrics?.length > 0 && this.capturePatientClinicalMetrics(provider.PracticeTenantId, patientId, encounter.ClinicalMetrics) || of(null)),
      catchError((err, req) => {
        console.error('complete consultation failed: ' + err);
        this.appInsightsService.trackException(err, 'completeEncounter', { patientId: patientId });
        return of({
          result: {
            Sucess: false, ...err,
            ResponseMessage: err?.ResponseMessage || err?.error || err?.message || '',
          },
        });
      }),
      tap(({ result, encounter }) => {
        if (result.Sucess) {
          // Push ad impressions on a successful save
          const adImpressions = this.getAdImpressions(encounter);
          if (adImpressions.length > 0) {
            this.merakiService.saveAdImpressions(adImpressions);
          }
          this.clinicalEncounterStore.remove(patientId);
          this.encounterLettersStore.remove(patientId);
          this.providerService.removeVisitFromWaitingRoom(encounter.PatientEventDetails.PatientEventId);
        } else if (result.Code == ERROR_CODE.DUPLICATE_REQUEST) {
          // todo handle duplicate error?
        }
      }),
      map(result => result.result),
    );
  }

  getPatientInProgressEncounterId(patientId: string) {
    return this.clinicalEncounterQuery.getPatientEncounterId(patientId);
  }

  addModifierToProceduresOnEncounter(tenantId: string, patientId: string, modifierLineItem: EncounterLineItemVo, procedureCodes: string[]) {
    const encounterLines = this.clinicalEncounterQuery.getEntity(patientId).EncounterLineItems.LineItems;

    const reorderedLines = (procedureCodes.length === 0)
      ? [...encounterLines, { ...modifierLineItem, LineNum: encounterLines.length + 1 }]
      : encounterLines.reduce((lines, encounterLine) => {
        if (encounterLine.LineType === 'Procedure' && procedureCodes.includes(encounterLine.ChargeCode)) {
          return [...lines, { ...encounterLine, LineNum: lines.length + 1 }, {
            ...modifierLineItem,
            LineNum: lines.length + 2,
          }];
        } else {
          return [...lines, { ...encounterLine, LineNum: lines.length + 1 }];
        }
      }, [] as EncounterLineItemVo[]);


    this.clinicalEncounterStore.update(patientId, entity => ({
      EncounterLineItems: {
        ...entity.EncounterLineItems,
        LineItems: reorderedLines,
      },
    }));
  }

  upsetAssistingProviderName(tenantId: string, patientId: string, providerName: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      AssistingDoctorName: providerName,
    }));
  }

  upsetAssistingProviderPracticeNumber(tenantId: string, patientId: string, practiceNumber: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      AssistingDoctorPracticeNumber: practiceNumber,
    }));
  }

  saveHtmlToPdf(html: string) {
    return this.clinicalClient.saveHtmlToPdf(html);
  }

  saveHtmlToPdfUrl(html: string, practiceId: string, accessToken: string, saveAs: string = null) {
    return this.clinicalClient.saveHtmlToPdf(html).pipe(map(data => this.getDownloadFileUrl(practiceId, accessToken, data.Data, saveAs)));
  }

  getDownloadFileUrl(practiceId: string, accessToken: string, filePath: string, saveAs: string = null) {
    return `${this.baseUrl}/api/v1/document?url=${filePath}&access_token=${accessToken}&practiceId=${practiceId}&saveAs=${saveAs}`;
  }

  sendCommunication(providerId: string, patientId: string, email: ProviderCommunicationEmailVo) {
    return this.clinicalPatientClient.sendCommunication(providerId, patientId, email);
  }

  diagnosesById$(practiceId: string, patientId: string) {
    return this.encounterInProgress$(practiceId, patientId).pipe(map(encounter => encounter.Diagnosis || []));
  }

  diagnosisTokensById$(practiceId: string, patientId: string) {
    return this.encounterInProgress$(practiceId, patientId).pipe(map(encounter => encounter.DiagnosisTokenItems || []));
  }

  encounterLinesById$(practiceId: string, patientId: string) {
    return this.encounterInProgress$(practiceId, patientId).pipe(map(encounter => encounter.EncounterLineItems.LineItems));
  }

  encounterHeaderById$(practiceId: string, patientId: string) {
    return this.encounterInProgress$(practiceId, patientId).pipe(map(encounter => encounter.EncounterLineItems.EncounterHeader));
  }

  encounterPrescriptionsById$(practiceId: string, patientId: string) {
    return this.encounterInProgress$(practiceId, patientId).pipe(map(encounter => encounter.MedicationsPrescriptions));
  }

  patientMetricsById(practiceId: string, patientId: string) {
    return combineLatest([this.clinicalEncounterQuery.selectEntity(patientId), this.referenceDataService.clinicalMetrics$]).pipe(
      map(([entity, clinicalMetric]) => {
        if (!entity || !entity.ClinicalMetrics) {
          return [];
        }
        const metrics = entity.ClinicalMetrics;
        const groupedMetrics = _.chain(metrics)
          .map(metric => {
            const metricData = clinicalMetric['ClinicalMetric:' + metric.Name];
            return {
              value: metric.Value,
              valueUnit: metric.Unit,
              valueFlag: metric.Type,
              date: new Date(metric.TestDate),
              ...metricData,
            } as IClinicalMetricValue;
          })
          .groupBy('type')
          .sortBy('type')
          .map(group => {
            const orderedResults = _.chain(group).sortBy('Date').reverse();
            const firstResult = orderedResults.first().value();
            return {
              allResults: orderedResults.value(),
              ...firstResult,
            };
          })
          .value();
        return groupedMetrics;
      }),
    );
  }

  upsetClinicalMetricsToEncounter(tenantId: string, patientId: string, metrics: ClinicalMetricVo[]) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      ClinicalMetrics: metrics,
    }));
  }

  upsetClinicalMetricToEncounter(tenantId: string, patientId: string, metric: ClinicalMetricVo) {
    this.clinicalEncounterStore.update(patientId, entity => {
      const findFn = (item) => item.Group === metric.Group && item.Name === metric.Name;
      return ({
        ClinicalMetrics: (entity.ClinicalMetrics.filter(findFn).length > 0)
          ? arrayUpdate(entity.ClinicalMetrics, item => item.Group === metric.Group && item.Name === metric.Name, metric)
          : arrayAdd(entity.ClinicalMetrics, metric),
      });
    });
  }

  removeClinicalMetricFromEncounter(tenantId: string, patientId: string, metricName: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      ClinicalMetrics: arrayRemove(entity.ClinicalMetrics, metricName),
    }));
  }

  removeClinicalCommunicationFromEncounter(tenantId: string, patientId: string, communicationId: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      Communications: arrayRemove(entity.Communications, communicationId, 'CommunicationId'),
    }));
  }

  hasInProgressPatientEncounter(practiceId: string, patientId: string) {
    return this.clinicalEncounterQuery.patientHasInProgressEncounter$(patientId);
  }

  searchProcedures(practiceId: string, lookupfilter: string, dateOfService: Date) {
    // todo switch to meily index
    return this.providerService.isForge$.pipe(
      take(1),
      withLatestFrom(this.referenceDataService.tarrifMedicalTypeCodes$, this.providerService.provider$),
      switchMap(([isForge, types, provider]) => true ? // NB using meily index for all providers (reducing florence pressure)
        this.searchTariffCodeIndex(lookupfilter, this.getTarrifMedicalType(types, provider.SpecialityCode))
        : this.loadProcedureFromFlorence(practiceId, lookupfilter, dateOfService))
    );
  }

  loadProcedureFromFlorence(practiceId: string, lookupfilter: string, dateOfService: Date): Observable<SearchResult[]> {
    return this.clinicalClient
      .getProcedures(practiceId, '00000000-0000-0000-0000-000000000001', lookupfilter, `${moment(dateOfService).format('YYYY-MM-DD')}`)
      .pipe(
        map(encs => _.flattenDeep(
          encs.map(enc => enc.LineItems.map(
            line => ({
              Code: line.ChargeCode,
              Description: line.ChargeDesc,
            } as SearchResult)
          )
          )
        )
        )
      );
  }

  getTarrifMedicalType(types: { SpecialityCode: number, Description: string, Type: string }[], specialityCode: string): string {
    const medicalType = types.find(s => s.SpecialityCode == Number(specialityCode));
    return medicalType?.Type || "MP";
  }

  searchTariffCodeIndex(lookupfilter: string, type: string): Observable<Hits<SearchResult>> {
    const index = this.meiliSearchLookupClient.index(`tariff_code`);
    const filter = [
      'TypeCode=10', `TariffMedicalTypeCode=${type}`
    ];

    const res = index.search<SearchResult>(lookupfilter, {
      filter,
      limit: 10,
    });

    return from(res).pipe(
      // todo once confirmed uncomment #36722
      map(s => s.hits?.filter(hit => !hit.Code.endsWith('A'))),
    );
  }

  getTariffCode(type: string, code: string): Promise<Hits<SearchResult>> {
    const index = this.meiliSearchLookupClient.index(`tariff_code`);
    const filter = [
      'TypeCode=10', `TariffMedicalTypeCode=${type}`,
      `Code=${code}`
    ];

    const res = index.search<SearchResult>('', {
      filter,
      limit: 10
    });

    return res.then(s => s.hits);

  }

  searchModifierCodeIndex(lookupfilter: string, type: string): Observable<Hits<SearchResult>> {
    const index = this.meiliSearchLookupClient.index(`tariff_code`);
    const filter = [
      'TypeCode=99', `TariffMedicalTypeCode=${type}`
    ];

    const res = index.search<SearchResult>(lookupfilter, {
      filter,
      limit: 10,
    });

    return from(res).pipe(
      map(s => s.hits),
    );
  }

  searchModifiers(practiceId: string, encounterId: string, lookupfilter: string) {
    return this.providerService.provider$.pipe(
      take(1),
      withLatestFrom(this.referenceDataService.tarrifMedicalTypeCodes$),
      switchMap(([provider, types]) => this.searchModifierCodeIndex(lookupfilter, this.getTarrifMedicalType(types, provider.SpecialityCode)))
    );
  }

  searchPrescription(practiceId: string, encounterId: string, lookupfilter: string) {
    return this.clinicalClient.getPrescriptions(practiceId, encounterId, lookupfilter);
  }

  getModifierInformation(practiceId: string, code: string) {
    return this.clinicalClient.getModifiersInfo(practiceId, code);
  }

  async requireManualProcessing(encounter: EncounterVo): Promise<string[]> {
    var manualProcessingReasons: string[] = [];
    //1. Is Cash Account
    if (encounter.EncounterType == EncounterType.CashInvoice) {
      manualProcessingReasons.push('Cash account');
    }
    const account = encounter.PatientEventDetails.Patient.PatientAccountDetails;
    if (encounter.EncounterType == EncounterType.MedicalAidInvoice) {
      //6. Medical aid details are incomplete
      if (!account.MedicalAidName || !account.MedicalAidPlan || !account.MedicalAidPlanOption ||
        !account.MedicalAidMembershipNumber) {
        manualProcessingReasons.push('Medical aid details are incomplete');
      }
      //7. Dependant Code not valid
      if (!account.MedicalAidDependentCode || !RegExp(AccountNoRegex).test(account.MedicalAidDependentCode)) {
        manualProcessingReasons.push('Dependant code is not valid');
      }
    }
    //3. Is HB to contact me selected
    var hbToContactMeSelected = encounter.RequiresFollowUp || encounter.Note?.length > 0;
    if (hbToContactMeSelected) {
      manualProcessingReasons.push('\'admin to contact me\' selected and / or comments provided');
    }

    //4. if Discovery KeyCare medical aid chosen with complex pricing logic
    /* var schemeName = (account.MedicalAidName ?? "").ToLower().Trim();
    if (provider?.ManualPricingSchemes != null &&
        provider.ManualPricingSchemes.Any(
            s => schemeName.Equals((s ?? "").ToLower().Trim())
                 || (s ?? "").Equals("*")))
    {
        manualProcessingReasons.push("Manual pricing scheme patient");
    } */
    //5. if account no. not provided
    var accountNo = account.AccountNo;
    if (!accountNo || !(RegExp(AccountNoRegex).test(accountNo))) {
      manualProcessingReasons.push('Account number is missing or invalid');
    }
    //8. Cellphone not valid

    var patientCellphone = encounter.PatientEventDetails.Patient.PatientDetails.ContactNo;
    var patientCellphoneValid = patientCellphone && RegExp(/^[0-9]*$/).test(patientCellphone);
    if (!patientCellphoneValid) {
      manualProcessingReasons.push('Patient cell phone is not valid');
    }

    var memberCellphone = account.MedicalAidMainMemberDetails.ContactNo;
    var memberCellphoneValid = memberCellphone && RegExp(/^[0-9]*$/).test(memberCellphone);
    if (!memberCellphoneValid) {
      manualProcessingReasons.push('Medical aid main member cell phone is not valid');
    }
    //9. check if doctor decided to get invoices over email
    /* var divertInvoiceToEmail = GetDivertInvoiceToEmail(provider);
    if (!string.IsNullOrEmpty(divertInvoiceToEmail) &&
      EmailRegex.IsMatch(divertInvoiceToEmail)) {
      manualProcessingReasons.Add("Doctor configured to receive invoice over email");
    } */
    //10. validate dateOfBirth
    var minDate = moment('1900-01-01').toDate();
    var dobValid = moment(account.MedicalAidMainMemberDetails.DateOfBirth).toDate() >= minDate &&
      moment(encounter.PatientEventDetails.Patient.PatientDetails.DateOfBirth).toDate() >= minDate;

    if (!dobValid) {
      manualProcessingReasons.push('Patient/main member date of birth is not valid');
    }

    const modifiers = encounter.EncounterLineItems.LineItems.filter(line => line.LineType === 'Modifier');
    if (modifiers.length > 0) {
      const modifierInfos = await Promise.all(modifiers.map(m => this.getModifierInformation(encounter.PatientEventDetails.PracticeId, m.ChargeCode).toPromise()));
      const notCalculatedModifiers = modifierInfos.filter(m => m.ImplementationStatus !== 'Calculated');
      if (notCalculatedModifiers.length > 0) {
        manualProcessingReasons.push('Contains not calculated modifier(s): ' + notCalculatedModifiers.join(','));
      }
    }

    return manualProcessingReasons;
    /*
    public static string[] InvoiceRequiresManualProcessing(InvoiceVo invoice,
            ProviderConfigurationVo provider)
        {
            var accountDetails = invoice.PatientEventDetails.Patient.PatientAccountDetails;

            var manualProcessingReasons = new List<string>();
            //1. Is Cash Account
            var cashAccount = CashInvoice.EqualsIgnoreCase(invoice.InvoiceType);
            if (cashAccount)
            {
                manualProcessingReasons.Add("Cash account");
            }

            if (MedicalAidInvoice.EqualsIgnoreCase(invoice.InvoiceType))
            {
                //6. Medical aid details are incomplete
                if ((string.IsNullOrWhiteSpace(accountDetails.MedicalAidName) ||
                     string.IsNullOrWhiteSpace(accountDetails.MedicalAidPlan) ||
                     string.IsNullOrWhiteSpace(accountDetails.MedicalAidPlanOption) ||
                     string.IsNullOrWhiteSpace(accountDetails.MedicalAidMembershipNumber)))
                {
                    manualProcessingReasons.Add("Medical aid details are incomplete");
                }

                //7. Dependant Code not valid
                int code;
                if (!int.TryParse(accountDetails.MedicalAidDependentCode, out code))
                {
                    manualProcessingReasons.Add("Dependant code is not valid");
                }
            }

            //3. Is HB to contact me selected
            var hbToContactMeSelected = invoice.RequiresFollowUp || !string.IsNullOrWhiteSpace(invoice.Note);
            if (hbToContactMeSelected)
            {
                manualProcessingReasons.Add("'admin to contact me' selected and / or comments provided");
            }

            //4. if Discovery KeyCare medical aid chosen with complex pricing logic
            var schemeName = (accountDetails.MedicalAidName ?? "").ToLower().Trim();
            if (provider?.ManualPricingSchemes != null &&
                provider.ManualPricingSchemes.Any(
                    s => schemeName.Equals((s ?? "").ToLower().Trim())
                         || (s ?? "").Equals("*")))
            {
                manualProcessingReasons.Add("Manual pricing scheme patient");
            }

            //5. if account no. not provided
            var accountNo = accountDetails.AccountNo;
            if (string.IsNullOrEmpty(accountNo) || !PatientValidationUtils.IsFieldValid(accountNo,
                PatientValidationUtils.ACCOUNT_NUMBER_REG))
            {
                manualProcessingReasons.Add("Account number is missing or invalid");
            }

            //8. Cellphone not valid
            long cellphoneNum;
            var patientCellphone = invoice.PatientEventDetails.Patient.PatientDetails.ContactNo;
            var patientCellphoneValid = string.IsNullOrEmpty(patientCellphone) ||
                                        long.TryParse(patientCellphone, out cellphoneNum);
            if (!patientCellphoneValid)
            {
                manualProcessingReasons.Add("Patient cell phone is not valid");
            }

            var memberCellphone = accountDetails.MedicalAidMainMemberDetails.ContactNo;
            var memberCellphoneValid = string.IsNullOrEmpty(memberCellphone) ||
                                       long.TryParse(memberCellphone, out cellphoneNum);
            if (!memberCellphoneValid)
            {
                manualProcessingReasons.Add("Medical aid main member cell phone is not valid");
            }

            //9. check if doctor decided to get invoices over email
            var divertInvoiceToEmail = GetDivertInvoiceToEmail(provider);
            if (!string.IsNullOrEmpty(divertInvoiceToEmail) &&
                EmailRegex.IsMatch(divertInvoiceToEmail))
            {
                manualProcessingReasons.Add("Doctor configured to receive invoice over email");
            }

            //10. validate dateOfBirth
            var minDate = DateTime.Parse("1900-01-01").Date;
            var dobValid = accountDetails.MedicalAidMainMemberDetails.DateOfBirth.Date >= minDate &&
                           invoice.PatientEventDetails.Patient.PatientDetails.DateOfBirth.Date >= minDate;
            if (!dobValid)
            {
                manualProcessingReasons.Add("Patient/main member date of birth is not valid");
            }

            if (provider.PracticeXRef != null && provider.PracticeXRef.ToLowerInvariant().Contains("ihealth"))
            {
                manualProcessingReasons.Add("iHealth claim unable to price");
            }

            var notCalculatedModifiers = ModifierService.GetNotCalculatedModifiersForInvoice(invoice);
            if (notCalculatedModifiers.Length > 0)
            {
                manualProcessingReasons.Add("Contains not calculated modifier(s): " +
                                            string.Join(",", notCalculatedModifiers));
            }

            return manualProcessingReasons.ToArray();
        }
    */
  }

  addSymptoms(practiceId: string, patientId: string, symptom: Symptom[]) {
    symptom.map(symp => this.addSymptom(practiceId, patientId, symp));
  }

  addSymptom(practiceId: string, patientId: string, symptom: Symptom, practiceNumber: string = '') {
    const currentSymptoms = this.clinicalEncounterQuery.getEntity(patientId).Symptoms;
    // don't add if we already added the symptom
    if (!currentSymptoms || !currentSymptoms.some(s => s.SymptomId === symptom.SymptomId)) {
      this.clinicalEncounterStore.update(patientId, entity => ({ Symptoms: arrayAdd(entity.Symptoms, symptom) }));

      if (symptom.Type === 'custom') {
        this.merakiService.getVisitReasonQuestionsByPractice(practiceNumber, symptom.SymptomId)
          .pipe(take(1))
          .subscribe({
            next: symptomQuestions =>
              this.addSymptomToEncounter(practiceId, patientId, symptom, symptomQuestions),
          });
      } else {
        // add the questions related to the symptom to the UI state
        this.merakiService.getVisitReasonQuestions(symptom.SymptomId)
          .pipe(take(1))
          .subscribe({
            next: symptomQuestions =>
              this.addSymptomToEncounter(practiceId, patientId, symptom, symptomQuestions),
          });
      }
    }
  }

  addGenericSymptom(practiceId: string, patientId: string, title: string) {
    const GENERIC = 'generic';

    const symptom = {
      SymptomId: `${GENERIC}-${title}`,
      Name: `${title.replace('-', ' ')}`,
      Type: 'complaint',
      SpecialityCodes: [GENERIC],
    } as Symptom;

    const currentSymptoms = this.clinicalEncounterQuery.getEntity(patientId).Symptoms;

    if (!currentSymptoms || !currentSymptoms.some(s => s.SymptomId === title)) {
      this.clinicalEncounterStore.update(patientId, entity => ({ Symptoms: arrayAdd(entity.Symptoms, symptom) }));

      // combineLatest([
      //   this.referenceDataService.getSymptomQuestions(GENERIC).pipe(map(g => g.map(s => ({ ...s, SymptomId: symptom.SymptomId })))),
      //   this.referenceDataService.getQuestionsBySymptom(GENERIC),
      //   this.referenceDataService.getQuestions(),
      // ])

      this.merakiService.getVisitReasonQuestions(GENERIC)
        .pipe(
          take(1),
          map(g => g.map(s => ({ ...s, SymptomId: symptom.SymptomId }))),
        ).subscribe({
          next: symptomQuestions =>
            this.addSymptomToEncounter(practiceId, patientId, symptom, symptomQuestions),
        });
    }
  }

  private addSymptomToEncounter(
    practiceId: string,
    patientId: string,
    symptom: Symptom,
    symptomQuestions: SymptomQuestion[],
  ) {
    const questionsForSymptom = symptomQuestions.filter(s => s.SymptomId === symptom.SymptomId).map(s => {
      const question = s.Question;

      // apply some logic to change some values around
      const isShared = question ? question.SharedValue : false;
      const QuestionKey = !isShared ? `${s.SymptomId}-${question.QuestionKey}` : question.QuestionKey;

      const existingQuestions = this.clinicalEncounterQuery.getEntity(patientId).SymptomQuestions;

      if (!existingQuestions.some(q => q.QuestionKey === QuestionKey)) {
        return this.ConvertToClinicalEncounterSymptomQuestion(symptom, s);
      } else {
        return null;
      }
    });

    this.addQuestionsForSymptom(practiceId, patientId, questionsForSymptom.filter(q => !!q));

  }

  private ConvertToClinicalEncounterSymptomQuestion(symptom: Symptom, symptQuestion: SymptomQuestion) {
    // find the referenced question
    // const question =  questions.map(q => ({ ...q, Order: symptQuestion.Order })).find(q => q.QuestionKey === symptQuestion.QuestionKey);
    const question = { ...symptQuestion.Question, Order: symptQuestion.Order || null };

    // apply some logic to change some values around
    const isShared = question ? question.SharedValue : false;
    const QuestionKey = !isShared ? `${symptQuestion.SymptomId}-${question.QuestionKey}` : question.QuestionKey;

    return {
      SymptomId: symptom.SymptomId,
      QuestionKey, // override the key if necessary
      Section: symptQuestion.Section,

      Question: {
        ...question,
        QuestionKey, // override the key if necessary
      },

      Dependency: symptQuestion.Dependency
        ? {
          ...symptQuestion.Dependency,
          DependencyKeyName: `${symptQuestion.SymptomId}-${symptQuestion.Dependency.DependencyKeyName}`,
        }
        : null,

      Questions: symptQuestion.Questions
        ? symptQuestion.Questions.map(q => this.ConvertToClinicalEncounterSymptomQuestion(symptom, q))
        : null,
    } as SymptomQuestion;
  }

  updateSymptom(practiceId: string, patientId: string, symptom: Symptom) {
    const currentSymptoms = this.clinicalEncounterQuery.getEntity(patientId).Symptoms;
    if (!currentSymptoms.some(s => s.SymptomId === symptom.SymptomId)) {
      // only update it if it exists
      this.clinicalEncounterStore.update(patientId, entity => ({
        Symptoms: arrayUpdate(entity.Symptoms, s => s.SymptomId === symptom.SymptomId, symptom),
      }));
    }
  }

  removeSymptom(practiceId: string, patientId: string, symptomId: string) {
    const currentSymptoms = this.clinicalEncounterQuery.getEntity(patientId).Symptoms;
    if (currentSymptoms.some(s => s.SymptomId === symptomId)) {
      // only remove it if you find it
      this.clinicalEncounterStore.update(patientId, entity => ({
        Symptoms: arrayRemove(entity.Symptoms, s => s.SymptomId === symptomId),
        SymptomQuestions: arrayRemove(entity.SymptomQuestions, s => s.SymptomId === symptomId),
        SymptomQuestionAnswers: arrayRemove(entity.SymptomQuestionAnswers, s => s.SymptomId === symptomId),
      }));
    }
  }

  addQuestionForSymptom(practiceId: string, patientId: string, question: SymptomQuestion) {
    this.clinicalEncounterStore.update(patientId, entity => ({ SymptomQuestions: arrayAdd(entity.SymptomQuestions, question) }));
  }

  addQuestionsForSymptom(practiceId: string, patientId: string, question: SymptomQuestion[]) {
    this.clinicalEncounterStore.update(patientId, entity => ({ SymptomQuestions: arrayAdd(entity.SymptomQuestions, question) }));
  }

  removeQuestionForSymptom(practiceId: string, patientId: string, QuestionKey: string) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      SymptomQuestions: arrayRemove(entity.SymptomQuestions, q => q.QuestionKey === QuestionKey),
      SymptomQuestionAnswers: arrayRemove(entity.SymptomQuestionAnswers, q => q.QuestionKey === QuestionKey),
    }));
  }

  updateSymptomQuestions(practiceId: string, patientId: string, SymptomQuestions: SymptomQuestion[]) {
    this.clinicalEncounterStore.update(patientId, { SymptomQuestions });
  }

  updateSymptomQuestion(practiceId: string, patientId: string, symptomQuestion: SymptomQuestion) {
    this.clinicalEncounterStore.update(patientId, entity => ({
      SymptomQuestions: arrayUpdate(
        entity.SymptomQuestions,
        s => s.SymptomId === symptomQuestion.SymptomId && s.QuestionKey === symptomQuestion.QuestionKey,
        symptomQuestion,
      ),
    }));
  }

  updateSymptomAnswer(
    practiceId: string,
    patientId: string,
    SymptomId: string,
    QuestionKey: string,
    AnswerValue: any,
    NoteValue: any,
    Section: SymptomQuestionSection,
    Notes: string,
  ) {
    this.clinicalEncounterQuery
      .selectEntity(patientId, ent => ent.SymptomQuestionAnswers)
      .pipe(
        take(1),
        tap(stateAnswers => {
          let answer: SymptomQuestionAnswer = _.cloneDeep(
            stateAnswers.find(a => a.QuestionKey === QuestionKey && a.SymptomId === SymptomId && a.Section === Section),
          );

          if (answer) {
            // check existing answer
            if (answer.AnswerValue !== AnswerValue || answer.Notes !== Notes) {
              // state is different, update
              answer.AnswerValue = AnswerValue;
              answer.NoteValue = NoteValue;
              answer.Notes = Notes;

              this.clinicalEncounterStore.update(patientId, entity => ({
                SymptomQuestionAnswers: arrayUpdate(
                  entity.SymptomQuestionAnswers,
                  a => a.QuestionKey === QuestionKey && a.SymptomId === SymptomId,
                  answer,
                ),
              }));
            }
          } else {
            // answer doesn't exist, add it
            answer = {
              SymptomId,
              QuestionKey,
              AnswerValue,
              NoteValue,
              Section,
              Notes,
            };
            this.clinicalEncounterStore.update(patientId, entity => ({
              SymptomQuestionAnswers: arrayAdd(entity.SymptomQuestionAnswers, answer),
            }));
          }
        }),
      )
      .subscribe();
  }

  getPatientSymptoms(practiceId: string, patientId: string) {
    return this.clinicalEncounterQuery.selectEntity(patientId, enc => enc.Symptoms);
  }

  getPatientSymptomQuestions(practiceId: string, patientId: string, section: SymptomQuestionSection) {
    return this.clinicalEncounterQuery
      .selectEntity(patientId, enc => enc.SymptomQuestions)
      .pipe(map(questions => questions.filter(q => !section || q.Section === section)));
  }

  getPatientSymptomQuestionsAndAnswers(practiceId: string, patientId: string, section: SymptomQuestionSection) {
    return this.clinicalEncounterQuery
      .selectEntity(patientId, enc => ({ questions: enc.SymptomQuestions, answer: enc.SymptomQuestionAnswers }))
      .pipe(
        map(com => ({
          questions: com.questions.filter(q => !section || q.Section === section),
          answers: com.answer.filter(q => !section || q.Section === section),
        })),
      );
  }

  getPatientSymptomAndQuestions(practiceId: string, patientId: string, section: SymptomQuestionSection) {
    return combineLatest([
      this.clinicalEncounterQuery.selectEntity(patientId, enc => enc.Symptoms).pipe(distinctUntilChanged()),
      this.clinicalEncounterQuery.selectEntity(patientId, enc => enc.SymptomQuestions).pipe(distinctUntilChanged()),
    ]).pipe(
      map(([stateSymptoms, stateSymptomQuestions]) =>
        !stateSymptoms
          ? []
          : stateSymptoms.map(symptom => ({
            ...symptom,
            Questions: (stateSymptomQuestions || [])
              .filter(q => q.SymptomId === symptom.SymptomId)
              .filter(q => !section || q.Section === section)
              .map(q => ({
                ...q.Question,
                Dependency: q.Dependency,
                Questions: q.Questions,
              })),
          })),
      ),
    );
  }

  getPatientSymptomQuestionAnswers(practiceId: string, patientId: string, section?: SymptomQuestionSection) {
    return combineLatest([
      this.clinicalEncounterQuery.selectEntity(patientId, enc => enc.SymptomQuestionAnswers),
      this.clinicalEncounterQuery.selectEntity(patientId, enc => enc.SymptomQuestions),
    ]).pipe(
      map(([answers, questions]) =>
        !answers
          ? null
          : answers
            .filter(a => !section || a.Section === section)
            .map(
              a =>
              ({
                ...a,
                Question: questions.find(q => q.QuestionKey === a.QuestionKey),
              } as SymptomQuestionAnswer),
            ),
      ),
    );
  }

  setEncounterDefaults(practiceId, patientId) {
    return this.providerService.branches$.pipe(
      map(branches =>
        branches.map(m => ({
          BranchName: m.BranchName,
          BranchXRef: m.BranchXRef,
          Enabled: m.Enabled,
          IsMainBranch: m.IsMainBranch,
          IsDefault: m.ProviderInfos?.some(s => s?.IsDefaultBranch) ?? false,
        })),
      ),
      tap(branches => {
        this.clinicalEncounterStore.update(patientId, entity => {
          return {
            ...entity,
            BranchXRef: entity?.BranchXRef ?? (branches.find(f => f.IsDefault)?.BranchXRef ?? ''),
          };
        });
      }),
    );
  }

  priceEncounter(practiceId: string, patientId: string) {
    const encounter = this.clinicalEncounterQuery.getEntity(patientId);

    if (!encounter) {
      return of({
        success: false,
        encounter: null,
        error: 'could not find encounter',
        tenantId: practiceId,
        patientId,
      });
    }

    const encounterUpdated = this.removeDosageInstructionFromEncounter(encounter);

    return this.priceEncounterForProvider(practiceId, encounterUpdated).pipe(
      map(result => {
        if (result && result.Sucess) {
          const serverEncounter = result.Data;

          const stateLines = this.clinicalEncounterQuery.getEntity(patientId)?.EncounterLineItems?.LineItems;
          serverEncounter?.EncounterLineItems?.LineItems.forEach(serverLine => {
            const stateLine = stateLines?.find(l => l.LineNum === serverLine.LineNum);

            if (!!stateLine && !!serverLine) {
              const newStateLine: EncounterLineItemVo = {
                ...stateLine,
                Amount: serverLine.Amount,
                TotalExcVat: serverLine.TotalExcVat,
                TotalIncVat: serverLine.TotalIncVat,
                UnitPrice: serverLine.UnitPrice,
              };

              this.clinicalEncounterStore.update(patientId, entity => ({
                ...entity,
                EncounterLineItems: {
                  ...entity.EncounterLineItems,
                  LineItems: arrayUpdate(entity.EncounterLineItems.LineItems, l => l.LineNum === newStateLine.LineNum, newStateLine),
                },
              }));
            }
          });

          return { success: true, encounter: serverEncounter, error: null, tenantId: practiceId, patientId };
        } else {
          return { success: false, encounter: null, error: result.ResponseMessage, tenantId: practiceId, patientId };
        }
      }),
    );
  }

  private priceEncounterForProvider(practiceId: string, encounter: EncounterVo): Observable<RestApiResultOfEncounterVo> {
    return this.providerService.provider$.pipe(
      take(1),
      withLatestFrom(this.providerService.isForge$.pipe(take(1))),
      switchMap(([provider, isForge]) => isForge
        ? this.merakiService.priceEncounter(practiceId, encounter.EncounterId, encounter, provider)
          .pipe(
            switchMap(result => {
              // use refresh endpoint to apply modifier calculations logic if any modifier added
              if (result && result.Sucess && encounter.EncounterLineItems.LineItems.find(item => item.LineType == 'Modifier') != null) {
                return this.clinicalClient.refreshEncounter(practiceId, encounter.EncounterId, ({ Encounter: result.Data }));
              }
              return of(result);
            },
            ),
          )
        : this.clinicalClient.priceEncounter(practiceId, encounter.EncounterId, encounter)),
    );
  }

  removeDosageInstruction(parameters: { [key: string]: string }): { [key: string]: string } {
    const { DosageInstructions, ...rest } = parameters;
    return rest;
  }

  removeDosageInstructionFromEncounter(encounter) {

    return {
      ...encounter,
      EncounterLineItems: {
        ...encounter.EncounterLineItems,
        LineItems: encounter.EncounterLineItems.LineItems.map(m => ({
          ...m,
          Parameters: m.Parameters ? this.removeDosageInstruction(m.Parameters) : m.Parameters,
        })),
      },
      MedicationsPrescriptions: encounter.MedicationsPrescriptions.map(m => ({
        ...m,
        MedicationPrescriptionLines: m.MedicationPrescriptionLines.map(p => ({
          ...p,
          Description: `${p.MedicationDescription} ${p.Parameters.DosageDescription}`,
          Parameters: p.Parameters ? this.removeDosageInstruction(p.Parameters) : p.Parameters,
        })),
      })),
    };
  }

  updateEncounter(tenantId: string, patientId: string, encounter: EncounterVo) {
    this.clinicalEncounterStore.update(patientId, this.reorderEncounterLinesForEncounter(encounter));
  }

  updateEncounterFromProviderConfigs(tenantId: string, patientId: string, configs: PatientConfigurationVo) {
    this.clinicalEncounterStore.update(patientId, entity => {

      const sendClaimToAdminByScheme = configs?.Configuration[ProviderConfigurationKey.SendClaimToAdminByScheme]?.split(',') || null;
      const patientMedicalAid = entity?.PatientEventDetails?.Patient?.PatientAccountDetails?.MedicalAidName || null;

      if (!sendClaimToAdminByScheme || !patientMedicalAid) {
        return entity;
      }

      return {
        ...entity,
        RequiresFollowUp: sendClaimToAdminByScheme.includes(patientMedicalAid.toLowerCase()),
      };
    });
  }

  updateEncounterPlaceOfService(tenantId: string, patientId: string, placeOfService: EncounterHeaderVoPlaceOfService) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      EncounterLineItems: {
        ...encounter.EncounterLineItems,
        EncounterHeader: {
          ...encounter.EncounterLineItems.EncounterHeader,
          PlaceOfService: placeOfService,
        },
      },
    }));
  }

  updateEncounterLine(tenantId: string, patientId: string, line: EncounterLineItemVo) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      EncounterLineItems: {
        ...encounter.EncounterLineItems,
        LineItems: arrayUpdate(encounter.EncounterLineItems.LineItems, entity => entity.LineNum === line.LineNum, line),
      },
    }));
  }

  createSpeechToNotesCollection(tenantId: string, encounterId: string, recordingDuration: number, micDevice: string) {
    return this.merakiService.createSpeechToNotesCollection(tenantId, encounterId, recordingDuration, micDevice)
  }

  updateSpeechToNotesNoteVersion(tenantId: string, encounterId: string, noteVersion: number) {
    return this.merakiService.updateSpeechToNotesNoteVersion(tenantId, encounterId, noteVersion)
  }

  updateSpeechToNotes(tenantId: string, encounterId: string, isGood: boolean, updatedNotes: string) {
    return this.merakiService.updateSpeechToNotes(tenantId, encounterId, isGood, updatedNotes)
  }

  updateClinicalNote(practiceId: string, patientId: string, clinicalNote: string) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      ClinicalNote: clinicalNote,
    }));
  }

  appendClinicalNote(practiceId: string, patientId: string, clinicalNote: string) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      ClinicalNote: (encounter.ClinicalNote || '') + clinicalNote,
    }));
  }

  updateAdminNote(practiceId: string, patientId: string, note: string) {
    this.clinicalEncounterStore.update(patientId, encounter => ({
      Note: note,
    }));
  }

  @action('uploadEncounterFile')
  uploadEncounterFile(tenantId: string, patientId: string, documentVo: PatientDocumentVo, file: Blob) {
    return this.patientsService.uploadPatientFile(tenantId, patientId, documentVo, file).pipe(
      map(response => response.document),
      tap(document => {
        this.clinicalEncounterStore.update(patientId, encounter => ({
          PatientDocuments: arrayAdd(encounter.PatientDocuments || [], {
            Category: document.Category,
            DocumentUrl: document.DocumentUrl,
          }),
        }));
      }),
      switchMap(() => this.saveEncounter(tenantId, patientId)),
    );
  }

  uploadEncounterDiagram(tenantId: string, patientId: string, documentVo: PatientDocumentVo, file: Blob) {
    return this.patientsService.uploadPatientFile(tenantId, patientId, documentVo, file).pipe(
      map(response => response.document),
    );
  }

  toggleQuickNotes() {
    this.showQuickNotesOnEncounterSummary$.next(!this.showQuickNotesOnEncounterSummary$.value);
  }

  predictionIcd(tenantId: string, request: ICDPredictionRequest) {
    return this.clinicalNewClient.predictionIcd(tenantId, request);
  }

  updateEncounterUiState(tenantId: string, patientId: string, uiState: Partial<ClinicalEncounterUIViewModel>) {
    this.clinicalEncounterStore.ui.update(patientId, uiState);
  }

  incrementDisplayCategories(tenantId: string, patientId: string) {
    this.clinicalEncounterStore.ui.update(patientId, state => {
      const newState = {
        ...state,
        categoriesToShow: (state?.categoriesToShow || DEFAULT_CATEGORIES) + 1,
        subCategoriesToDisplay: (state?.subCategoriesToDisplay || DEFAULT_CATEGORIES) + 1,
      };
      return newState;
    });
  }

  searchDiagnosis(tenantId: string, encounterId: string, icd: string) {
    // compatibility fix to allow get diagnosis from florence backend when algolia gave icd code with / instead of . (M8633/1, etc)
    return this.clinicalClient.getDiagnosisRanking(tenantId, encounterId, icd.replace('/', '.'))
      .pipe(
        map(s => s?.map(d => ({
          ...d,
          EncounterHeader: {
            ...d.EncounterHeader,
            Diagnosis: d.EncounterHeader.Diagnosis.map(diag => ({ ...diag, DiagnosisCode: icd }))
          }
        }))),
      );
  }

  getDagnosisToken(diagnosis: DiagnosisVo2): Observable<DiagnosisVo2> {
    return this.algoliaRestService
      .getAlgoliaDiagnosisToken(diagnosis.DiagnosisCode)
      .pipe(map(DiagnosisToken => ({ ...diagnosis, DiagnosisToken } as DiagnosisVo2)));
  }

  upsertDiagnosisCodeToEncounter(tenantId: string, patientId: string, icdCode: string) {
    return this.encounterInProgress$(tenantId, patientId).pipe(
      switchMap(encounter => this.searchDiagnosis(tenantId, encounter.EncounterId, icdCode)),
      map(results => {
        if (!results) {
          return null;
        }
        const diagnosis =
          results &&
          results.flatMap(line => line.EncounterHeader.Diagnosis.flatMap(diag => diag)).find(diag => diag.DiagnosisCode === icdCode);
        return diagnosis;
      }),
      tap(diagnosis => {
        if (!diagnosis) {
          console.error('Could not find diagnosis');
        }
      }),
      filter(diagnosis => !!diagnosis),
      tap(diagnosis => this.upsertDiagnosisVoToEncounter(tenantId, patientId, diagnosis)),
    );
  }

  getRxHistory(tenantId: string, patientId: string, rxType?: 'dispensed' | 'prescribed' | 'all' | null) {
    //return this.clinicalNewClient.getEncountersRxHistory(tenantId, patientId, rxType);
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider => this.merakiService.getEncountersRxHistory(provider.PracticeTenantId, patientId, rxType)),
    );
  }

  getMedicineInforByATC5(tenantId: string, ingredient: string) {
    return this.clinicalNewClient.getMedicineInforByATC5(tenantId, ingredient);
  }

  getFormularyInformationForNappiAndSPO(tenantId: string, nappiCode: string, schemeCode: string, planCode: string, optionCode: string) {
    const formularyType = FormularyType._1; // chronic
    return this.clinicalNewClient.getFormularyInfo(tenantId, formularyType, nappiCode, schemeCode, planCode, optionCode);
  }

  priceConsumables(tenantId: string, patientId: string, consumables: ConsumableViewModel[]) {
    return this.providerService.specialityRule$.pipe(
      take(1),
      map(specialityRule => {
        let fakeEncounter = this.clinicalEncounterQuery.getEntity(patientId);

        return (fakeEncounter = {
          ...fakeEncounter,
          EncounterId: uuidV4(),
          EncounterLineItems: {
            ...fakeEncounter.EncounterLineItems,
            LineItems: consumables.map(
              (c, i) =>
              ({
                LineType: 'Consumable',
                LineNum: ++i,
                ChargeCode: specialityRule.ConsumableProcedureCode,
                ChargeDesc: c.ConsumableDescription,
                NappiCode: c.NAPPIFullCode,
                ChargeQuan: 1,
                UnitPrice: null,
                TotalExcVat: null,
                TotalIncVat: null,
                Amount: null,
                PriceOverride: false,
                CreatedFrom: c.NAPPIShortCode,
                Parameters: {
                  CategoryCode: c.CategoryCode,
                  SubCategoryCode: c.SubCategoryCode,
                  Token: `C-${specialityRule.ConsumableProcedureCode}-${c.SubCategoryCode}`,
                },
              } as EncounterLineItemVo),
            ),
          },
        });
      }),
      switchMap(encounter => this.priceEncounterForProvider(tenantId, encounter)),
      //switchMap(encounter => this.clinicalClient.priceEncounter(tenantId, encounter.EncounterId, encounter))
    );
  }

  priceMedicines(tenantId: string, patientId: string, medicines: EncounterLineItemVo[]) {
    let fakeEncounter = this.clinicalEncounterQuery.getEntity(patientId);
    fakeEncounter = {
      ...fakeEncounter,
      EncounterId: uuidV4(),
      EncounterLineItems: {
        ...fakeEncounter.EncounterLineItems,
        LineItems: medicines.map(
          (c, i) =>
          ({
            LineNum: ++i,
            ...c,
            ChargeQuan: this.getEncounterLineQuantitySum(fakeEncounter, c),
            Diagnosis: fakeEncounter.EncounterLineItems.EncounterHeader.Diagnosis,
          } as EncounterLineItemVo),
        ),
      },
    };
    return this.priceEncounterForProvider(tenantId, fakeEncounter);
    //return this.clinicalClient.priceEncounter(tenantId, fakeEncounter.EncounterId, fakeEncounter);
  }

  priceMedicineLine(tenantId: string, patientId: string, medicines: EncounterLineItemVo[]) {
    let fakeEncounter = this.clinicalEncounterQuery.getEntity(patientId);
    fakeEncounter = {
      ...fakeEncounter,
      EncounterId: uuidV4(),
      EncounterLineItems: {
        ...fakeEncounter.EncounterLineItems,
        LineItems: medicines.map(
          (c, i) =>
          ({
            LineNum: ++i,
            ...c,
            Diagnosis: fakeEncounter.EncounterLineItems.EncounterHeader.Diagnosis,
          } as EncounterLineItemVo),
        ),
      },
    };

    const encounterUpdated = this.removeDosageInstructionFromEncounter(fakeEncounter);

    return this.priceEncounterForProvider(tenantId, encounterUpdated);
    //return this.clinicalClient.priceEncounter(tenantId, fakeEncounter.EncounterId, encounterUpdated);
  }

  getEnrichedMedicineInfoForPatient(tenantId: string, patientId: string, medicines: MedicineInfo[]) {
    return combineLatest([
      this.patientsService.patientAccountDetailsById$(tenantId, patientId),
      this.patientsService.patientConditionsById$(tenantId, patientId).pipe(
        map(conditions => (conditions && conditions.Conditions) || []),
        map(conditions => conditions.flatMap(condition => condition.Medications || [])),
        // only registered, return only NappiCode
        map(medications => medications.filter(medication => medication.IsRegistered).map(medication => medication.NappiCode)),
      ),
    ]).pipe(
      map(([patientAccountInfo, registeredNappis]) => {
        const spoList = patientAccountInfo.IsCashAccount
          ? []
          : [patientAccountInfo.MedicalAidSchemeCode, patientAccountInfo.MedicalAidPlanCode, patientAccountInfo.MedicalAidOptionCode];

        const medicineViewModels = medicines.map(m => ({ MedicineInfo: m } as MedicineViewModel));

        return enrichMedicineViewModelsInfoFlags(medicineViewModels, spoList, registeredNappis).map(m => m.MedicineInfo);
      }),
    );
  }

  medicineInteractions$(patientId: string): Observable<MedicineInteractionVo[]> {
    return this.clinicalEncounterQuery.encounterUiState$(patientId).pipe(
      map(entity => entity?.medicineInteractions || []),
    );
  }

  medicineInteractionStatus$(patientId: string): Observable<MedicineInteractionStatus> {
    return this.clinicalEncounterQuery.encounterUiState$(patientId).pipe(
      map(entity => entity?.medicineInteractionStatus || MedicineInteractionStatus.None),
    );
  }

  updateMedicineInteractions(patientId: string, tenantId: string, interactions: MedicineInteractionVo[]) {
    this.updateEncounterUiState(tenantId, patientId, { medicineInteractions: interactions });
  }


  renderKahunPatientSummary(practiceId: string, patientId: string) {
    return this.providerService.appointments$.pipe(
      map(app => app.find(f => f.PatientId === patientId)),
      switchMap(app => this.merakiService.getKahunPatientSummary(practiceId, app?.CalendarEventXRef).pipe(map(m => ({
        ...m,
        appointmentId: app?.CalendarEventXRef,
      })))),
      tap(kAppointment => {

        if (!kAppointment) {
          return;
        }

        this.clinicalEncounterStore.ui.update(patientId, uiState => {
          const newState = {
            ...uiState,
            kahunAppointment: kAppointment,
          };
          return newState;
        });

      }),
      catchError(err => {
        console.error(err);
        return of(null);
      }),
    );

  }

  addAdImpression(patientId: string, adImpression: AdImpression) {
    this.clinicalEncounterStore.ui.update(patientId, (state) => {
      const updState = {
        ...state,
        adImpressions: [...(state.adImpressions || []), adImpression],
      };
      return updState;
    });
  }

  private getAdImpressions(encounter: EncounterVo): AdImpression[] {
    const impressions = this.clinicalEncounterQuery.ui.getEntity(encounter.PatientId)?.adImpressions || [];
    const result: AdImpression[] = [];
    impressions.forEach(impression => {
      const existingImpression = result.find(a => impression.IsDispensed ? a.NAPPICode10 === impression.NAPPICode10 : a.NAPPICode7 === impression.NAPPICode7);
      if (!existingImpression) {
        let hasMatchingLine = false;

        if (impression.IsDispensed) {
          hasMatchingLine = encounter.EncounterLineItems?.LineItems?.some(item => item.NappiCode && impression.NAPPICode10 && (item.NappiCode.padStart(10, '0')) === impression.NAPPICode10);
        } else {
          hasMatchingLine = encounter.MedicationsPrescriptions?.some(item => item.MedicationPrescriptionLines?.some(line => line.NappiCode === impression.NAPPICode7));
        }

        if (hasMatchingLine) {
          const encounterTimestamp = new Date();
          result.push({
            ...impression,
            EncounterTimestamp: encounterTimestamp,
            EncounterTimestampText: moment(encounterTimestamp).format('YYYY-MM-DD HH:mm'),
            Status: 'NEW',
          });
        }
      }
    });
    return result;
  }
}

