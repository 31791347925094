import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFromString'
})
export class DateFromStringPipe implements PipeTransform {

  transform(value: string, format: string): string {
    if (!value) {
      return '';
    }
    return moment(value).format(format).toString();
  }

}
